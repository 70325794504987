/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./headline.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./headline.component";
var styles_HeadlineComponent = [i0.styles];
var RenderType_HeadlineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeadlineComponent, data: {} });
export { RenderType_HeadlineComponent as RenderType_HeadlineComponent };
export function View_HeadlineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "headline"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headline; _ck(_v, 1, 0, currVal_0); }); }
export function View_HeadlineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-headline", [], null, null, null, View_HeadlineComponent_0, RenderType_HeadlineComponent)), i1.ɵdid(1, 49152, null, 0, i2.HeadlineComponent, [], null, null)], null, null); }
var HeadlineComponentNgFactory = i1.ɵccf("ato-headline", i2.HeadlineComponent, View_HeadlineComponent_Host_0, { headline: "headline" }, {}, []);
export { HeadlineComponentNgFactory as HeadlineComponentNgFactory };
