<div class="image-teaser lazyload-trigger" *ngIf="showTemplate" [preRender]="false" (deferLoad)="showImages=true">
    <div class="image-teaser__big-image">
        <div class="image-teaser__big-image__inner">
            <ato-image *ngIf="showImages" [image]="imageTeaserData.images.bigImage" [shadow]="true" class="lazyload"></ato-image>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-xs-4 col-md-5 col-md-push-3 col-lg-5 col-lg-push-3 col-xl-7 col-xl-push-5">
                <div class="image-teaser__textbox">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xs-4 col-xl-push-1">
                                <ato-subheadline [subheadline]="imageTeaserData.subheadline"></ato-subheadline>
                                <ato-headline [headline]="imageTeaserData.headline"></ato-headline>
                            </div>
                            <div class="col-xs-4 col-xl-10 col-xl-push-2">
                                <ato-text-paragraph [text]="imageTeaserData.text"></ato-text-paragraph>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="image-teaser__small-image">
        <div class="image-teaser__small-image__inner">
            <ato-image *ngIf="showImages" [image]="imageTeaserData.images.smallImage" [shadow]="true" class="lazyload"></ato-image>
        </div>
    </div>
</div>
