import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DataApi} from "@rem/data";

@Component({
    selector: "ato-logo",
    templateUrl: "./logo.component.html",
    styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit, OnDestroy {
    @Input() public whitelabel: string;
    public data: any;
    private responseEditorial: any;
    private responseGlobal: any;
    public cityLogo: string;
    public altText: string;
    public redirectTo: string;
    public root: string;

    private routerUrl_: Subscription;
    private routeParams_: Subscription;

    public bayernwerkLogoPath: string;
    public bayernwerkLogoAlt: string;

    constructor(
        private route: ActivatedRoute,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.responseGlobal = await DataApi.getGlobalData();
        this.responseEditorial = await DataApi.getRegionData(this.whitelabel);

        this.routerUrl_ = this.route.url.subscribe(url => {
            this.redirectTo = "/" + this.whitelabel;
            this.root = "/";
        });

        this.data = this.responseEditorial;
        if (this.data && this.data.landingPage.srcPath) {
            this.cityLogo = this.data.landingPage.srcPath;
        }

        if (this.responseGlobal && this.responseGlobal.logo) {
            this.bayernwerkLogoPath = this.responseGlobal.logo.image;
            this.bayernwerkLogoAlt = this.responseGlobal.logo.alt;
        }

        this.altText = this.data && this.data.landingPage ? this.data.landingPage.imgAltText : "Logo";
    }

    ngOnDestroy(): void {
        if (this.routeParams_) {
            this.routeParams_.unsubscribe();
        }
        if (this.routerUrl_) {
            this.routerUrl_.unsubscribe();
        }
    }
}
