/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-form-template.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../atoms/image/image.component.ngfactory";
import * as i3 from "../../atoms/image/image.component";
import * as i4 from "@trademe/ng-defer-load/dist/defer-load.directive";
import * as i5 from "@angular/common";
import * as i6 from "../../organisms/contact-form/contact-form.component.ngfactory";
import * as i7 from "../../organisms/contact-form/contact-form.component";
import * as i8 from "./contact-form-template.component";
var styles_ContactFormTemplateComponent = [i0.styles];
var RenderType_ContactFormTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactFormTemplateComponent, data: {} });
export { RenderType_ContactFormTemplateComponent as RenderType_ContactFormTemplateComponent };
function View_ContactFormTemplateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-image", [["class", "lazyload"]], null, null, null, i2.View_ImageComponent_0, i2.RenderType_ImageComponent)), i1.ɵdid(1, 49152, null, 0, i3.ImageComponent, [], { image: [0, "image"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.producerForm.image; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ContactFormTemplateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.producerForm.content.buttonOpen; _ck(_v, 0, 0, currVal_0); }); }
function View_ContactFormTemplateComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.producerForm.content.buttonClose; _ck(_v, 0, 0, currVal_0); }); }
function View_ContactFormTemplateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "main", [["class", "contactFormTemplate lazyload-trigger"]], null, [[null, "deferLoad"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deferLoad" === en)) {
        var pd_0 = ((_co.showImage = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4407296, null, 0, i4.DeferLoadDirective, [i1.ElementRef, i1.NgZone, i1.PLATFORM_ID], null, { deferLoad: "deferLoad" }), (_l()(), i1.ɵeld(2, 0, null, null, 17, "section", [["class", "contactFormTemplate__subheader"], ["id", "producerForm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "contact-form__subheader"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactFormTemplateComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "contact-form__subheader__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 10, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "col-xs-4 col-xl-10 col-xl-push-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "h4", [["class", "contact-form__switch-contracts__headline"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 4, "button", [["class", "toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.formToggler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactFormTemplateComponent_3)), i1.ɵdid(16, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactFormTemplateComponent_4)), i1.ɵdid(18, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 0, "button", [["class", "cross"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.formToggler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "section", [["class", "contactFormTemplate__form"]], [[2, "open", null]], null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "org-contact-form", [], null, null, null, i6.View_ContactFormComponent_0, i6.RenderType_ContactFormComponent)), i1.ɵdid(22, 114688, null, 0, i7.ContactFormComponent, [], { whitelabel: [0, "whitelabel"], globalData: [1, "globalData"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.showImage; _ck(_v, 5, 0, currVal_0); var currVal_3 = !_co.toggleForm; _ck(_v, 16, 0, currVal_3); var currVal_4 = _co.toggleForm; _ck(_v, 18, 0, currVal_4); var currVal_7 = _co.whitelabel; var currVal_8 = _co.globalData; _ck(_v, 22, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.producerForm.content.headline; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.producerForm.content.text; _ck(_v, 13, 0, currVal_2); var currVal_5 = _co.toggleForm; _ck(_v, 19, 0, currVal_5); var currVal_6 = _co.toggleForm; _ck(_v, 20, 0, currVal_6); }); }
export function View_ContactFormTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactFormTemplateComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTemplate; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ContactFormTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-contact-form", [], null, null, null, View_ContactFormTemplateComponent_0, RenderType_ContactFormTemplateComponent)), i1.ɵdid(1, 114688, null, 0, i8.ContactFormTemplateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactFormTemplateComponentNgFactory = i1.ɵccf("tpl-contact-form", i8.ContactFormTemplateComponent, View_ContactFormTemplateComponent_Host_0, { globalData: "globalData", producerForm: "producerForm", whitelabel: "whitelabel" }, {}, []);
export { ContactFormTemplateComponentNgFactory as ContactFormTemplateComponentNgFactory };
