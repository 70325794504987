import { API_ROOT_URL } from "./env";
import { Http } from "@rem/http";
import { ApiErrorFactory } from "@rem/error";
import { Salutation } from "./customer";
import { ProductionType } from "./lead";

export interface Producer {
	id: string;
	address: {
		contact: string;
		zipcode: string;
		city: string;
		street: string;
		houseNumber: string;
	};
	installedCapacity: number;
	maxHouseholds: number;
	yearOfCommissioning: number /*  - Default value in UI: 2018
                                    - May receive incorrect values from PC such as: 20125
                                    // TODO(Mark): Talk to Susanne about this */;
	latitude: string; // String by design   // TODO(Mark): Talk to Wolfgang about this
	longitude: string; // String by design  // TODO(Mark): Talk to Wolfgang about this
	producerName: string;
	description: string;
	assumedAnnualGeneration: number;
	type: string;
	images?: {
		profile: string;
		detail: string;
	};
}

export class ProducersApi {
	static async get(region: string): Promise<Producer[]> {
		let res = await fetch(`${API_ROOT_URL}/api/ova/producers?regionid=${region}`, {
			method: "get"
		});
		let resBody: ResBody = await res.json();

		if (res.status !== Http.OKAY) {
			if (resBody.errors && resBody.errors.length) {
				const error = { code: "UNKNOWN" };
				throw ApiErrorFactory.createError(error);
			}
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}

		return resBody.data.map(producer => {
			return {
				id: producer.id,
				images: {
					profile: `${API_ROOT_URL}/assets/${region}/producers/${producer.id}/images/profile.jpg`,
					detail: `${API_ROOT_URL}/assets/${region}/producers/${producer.id}/images/details.jpg`
				},
				...producer.attributes
			};
		});
	}

	static async post(producerInterest: ProducerInterest): Promise<void> {
		let res = await fetch(`${API_ROOT_URL}/api/ova/producerinterest`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				data: {
					type: "producerinterest",
					attributes: producerInterest
				}
			})
		});
		if (res.status !== Http.CREATED) {
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
	}
}

export interface ProducerInterest {
	regionId: string;
	salutation: Salutation;
	firstname: string;
	lastname: string;
	email: string;
	phoneNumber: string;
	zipcode: string;
	city: string;
	street: string;
	houseNumber: string;
	tosAccepted: boolean;
	assumedAnnualGeneration: number;
	installedCapacity: number;
	productionType: ProductionType;
	yearOfCommissioning: string;
}

type ResBody = {
	errors?: any[];
	data: {
		id: string;
		type: "producer";
		attributes: Producer;
	}[];
};
