<div class="logo">
    <!-- The only way to be sure links are disabled is to replace the a tag with just the img -->
    <a *ngIf="redirectTo" [routerLink]="redirectTo" class="region">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
    </a>
    <div *ngIf="cityLogo" class="separator"></div>
    <a *ngIf="root && bayernwerkLogoPath" [routerLink]="root" class="revu">
        <img class="revu" [src]="bayernwerkLogoPath" [alt]="bayernwerkLogoAlt">
    </a>
</div>
<ng-template #logo>
    <img *ngIf="cityLogo" class="region" [src]="cityLogo" [alt]="altText">
</ng-template>
