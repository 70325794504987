<div class="textbox">
    <div class="container">
        <div class="row">
            <div class="col-xs-4 col-md-7 col-lg-9 col-xl-9">
                <ato-subheadline [subheadline]="textData.subheadline"></ato-subheadline>
                <ato-headline [headline]="textData.headline"></ato-headline>
            </div>
            <div class="col-xs-4 col-md-7 col-md-push-1 col-lg-7 col-lg-push-1 col-xl-8 col-xl-push-1">
                <ato-text-paragraph [text]="textData.text"></ato-text-paragraph>
                <mol-call-to-action *ngIf="textData.callToAction" [callToActionData]="textData.callToAction"></mol-call-to-action>
            </div>
        </div>
    </div>
</div>
