<div class="header-wrapper" [ngClass]="{ 'is-fixed': isFixed }">
	<header class="pageHeader">
		<!-- logo commune -->
		<ato-logo [whitelabel]="whitelabel"></ato-logo>

		<div class="page-header__toolbar">
			<!-- tell a friend -->
			<div class="tellafriend" *ngIf="this.hasTellAFriendSection()">
				<button (click)="this.scrollToTellAFriend()" class="tellafriend-logo"><span></span>Freunde werben</button>
			</div>

			<!-- login -->
			<div class="pageHeader__profilePicture">
				<a
					(click)="onRoute()"
					*ngIf="this.showLoginButton"
					[routerLink]="['/' + this.whitelabel, this.isLoggedIn ? 'kundenportal' : 'login']"
					routerLinkActive="active"
					class="profile"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="40" height="36" viewBox="3 5 30 25" class="icon">
						<path
							fill-rule="evenodd"
							d="M15.75 9.75c.594-.594 1.344-.891 2.25-.891.906 0 1.656.297 2.25.89.594.594.89
              1.344.89 2.25 0 .906-.296 1.656-.89 2.25-.594.593-1.344.89-2.25.89-.906 0-1.656-.297-2.25-.89-.594-.594-.89-1.344-.89-2.25
              0-.906.296-1.656.89-2.25zm-2.063 13.216a16.138 16.138 0 0 1 4.313-.61c1.406 0 2.844.204 4.313.61 1.468.406 2.64.851 3.515
              1.336.875.484 1.313.882 1.313 1.195L27.08 30H8.799l.06-4.503c0-.313.438-.711 1.313-1.195.875-.485 2.047-.93 3.515-1.336zM22.267
              7.78C21.078 6.594 19.656 6 18 6c-1.656 0-3.07.586-4.242 1.758C12.586 8.929 12 10.343 12 11.999c0 1.656.586 3.07 1.758 4.241
              1.172 1.172 2.586 1.758 4.242 1.758 1.656 0 3.07-.586 4.242-1.758C23.414 15.07 24 13.655 24 12c0-1.656-.578-3.062-1.734-4.218zm.656
              12.326c-1.781-.406-3.422-.61-4.922-.61-1.5 0-3.14.204-4.922.61a14.718 14.718 0 0 0-4.875 2.062C6.734 23.138 6 24.247 6
              25.497v4.499h24v-4.5c0-1.25-.734-2.358-2.203-3.327a14.718 14.718 0 0 0-4.875-2.062z"
						/>
					</svg>
					<span class="text" *ngIf="this.isLoggedIn">Kundenportal</span>
					<span class="text" *ngIf="!this.isLoggedIn">Login</span>
				</a>
			</div>

			<!-- logout -->
			<div class="pageHeader__logout" *ngIf="this.isLoggedIn">
				<div class="pageHeader__logout__logoutPicture">
					<a (click)="logout()" [routerLink]="['/' + this.whitelabel]" routerLinkActive="active" class="logout">
						<svg xmlns="http://www.w3.org/2000/svg" width="40" height="36" viewBox="0 0 40 36" class="icon">
							<path
								fill-rule="evenodd"
								d="M33.63 25.422c.56.32.752 1.026.428 1.579C30.893 32.397 24.982 36 18.25 36 8.162 36 0
                27.949 0 18 0 8.05 8.163 0 18.25 0c6.729 0 12.642 3.6 15.808 8.999.324.553.133 1.26-.427 1.58-.56.319-1.278.13-1.602-.423A15.944
                15.944 0 0 0 18.25 2.312C9.48 2.312 2.344 9.349 2.344 18c0 8.65 7.135 15.688 15.906 15.688 5.88 0 11.027-3.15 13.78-7.845a1.181
                1.181 0 0 1 1.6-.421zm5.875-8.367a1.149 1.149 0 0 1 0 1.89l-4.28 2.976c-.77.535-1.847-.004-1.847-.945v-1.82h-20.55A1.164 1.164
                0 0 1 11.656 18c0-.638.525-1.156 1.172-1.156h20.55v-1.82c0-.935 1.072-1.484 1.847-.945l4.28 2.976z"
							/>
						</svg>
						<span class="text">Logout</span>
					</a>
				</div>
			</div>

			<ato-cta-button
				*ngIf="configuration && configuration.callToAction.show"
				[ctaConfig]="{
					label: configuration.callToAction.label,
					clickEvent: scrollToPriceCalculator,
					cssClass: configuration.callToAction.cssClass,
					showIcon: configuration.callToAction.showIcon
				}"
			></ato-cta-button>
		</div>
	</header>
</div>
