<ul class="container quote-wrapper lazyload-trigger" [preRender]="false" (deferLoad)="showImage=true" #quoteContainer>

	<li #quoteElem class="quote" *ngFor="let quote of quoteData.quotes; let i = index; let first = first;" [class.active]="first" [style.zIndex]="quoteData.quotes.length - i">

		<!-- smae size on all breakpoints -->
		<figure *ngIf="quote.image">
			<img [src]="quote.image" [alt]="quote.imageAlt" class="lazyload" *ngIf="showImage" class="quote_image">
			<figcaption [innerHTML]="quote.imageCaption"></figcaption>
		</figure>

		<div class="quote-text">

			<p class="quote__big-text">
				<span class="quote-text-start">
					<svg width="49px" height="43px" viewBox="0 0 49 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="Neue-Homepage-Copy-8" transform="translate(-458.000000, -3772.000000)" fill="#DEDDDD">
								<g id="Group-15" transform="translate(0.000000, 3421.000000)">
									<g id="Group" transform="translate(458.000000, 144.000000)">
										<g id="qutoe" transform="translate(0.000000, 207.000000)">
											<path d="M2.02702703,43 L2.02702703,36.8571429 C3.91892838,35.8056575 5.33783311,33.9102454 6.28378378,31.1708494 C7.22973446,28.4314535 7.7027027,24.8204857 7.7027027,20.3378378 L7.7027027,18.4285714 L0,18.4285714 L0,0 L18,0 L18,13.7799228 C18,22.1364639 16.6621755,28.7496539 13.9864865,33.6196911 C11.3107974,38.4897283 7.32435081,41.6164667 2.02702703,43 Z M33.027027,43 L33.027027,36.8571429 C34.9189284,35.8056575 36.3378331,33.9102454 37.2837838,31.1708494 C38.2297345,28.4314535 38.7027027,24.8204857 38.7027027,20.3378378 L38.7027027,18.4285714 L31,18.4285714 L31,0 L49,0 L49,13.7799228 C49,22.1364639 47.6621755,28.7496539 44.9864865,33.6196911 C42.3107974,38.4897283 38.3243508,41.6164667 33.027027,43 Z" id="99"></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</span>
				{{ quote.quote }}
				<span class="quote-text-end">
					<svg width="50px" height="43px" viewBox="0 0 50 43" version="1.1" xmlns="http://www.w3.org/2000/svg">
						<title>„</title>
						<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<g id="Neue-Homepage-Copy-8" transform="translate(-1051.000000, -3915.000000)" fill="#DEDDDD">
								<g id="Group-15" transform="translate(0.000000, 3421.000000)">
									<g id="Group" transform="translate(458.000000, 144.000000)">
										<g id="qutoe" transform="translate(0.000000, 207.000000)">
											<path d="M595.085563,186 L595.085563,179.857143 C597.032099,178.805658 598.491978,176.910245 599.465246,174.170849 C600.438514,171.431453 600.92514,167.820486 600.92514,163.337838 L600.92514,161.428571 L593,161.428571 L593,143 L611.519802,143 L611.519802,156.779923 C611.519802,165.136464 610.143344,171.749654 607.390387,176.619691 C604.637429,181.489728 600.535863,184.616467 595.085563,186 Z M625.9519,186 L625.9519,179.857143 C627.898435,178.805658 629.358315,176.910245 630.331583,174.170849 C631.304851,171.431453 631.791477,167.820486 631.791477,163.337838 L631.791477,161.428571 L623.866337,161.428571 L623.866337,143 L642.386139,143 L642.386139,156.779923 C642.386139,165.136464 641.009681,171.749654 638.256723,176.619691 C635.503766,181.489728 631.402199,184.616467 625.9519,186 Z" id="„" transform="translate(617.693069, 164.500000) scale(-1, -1) translate(-617.693069, -164.500000) "></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</span>
			</p>

		</div>

		<figcaption *ngIf="!quote.image">{{ quote.imageCaption }}</figcaption>

		<a class="quote-link" *ngIf="quote.urlInterview" [href]="quote.urlInterview" >
			<span class="quote-link-text" [innerHTML]="quoteData.buttonLabel"></span>
			<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
				<path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z" />
			</svg>
		</a>

	</li>

</ul>

