import {Component, Input} from "@angular/core";

@Component({
    selector: "mol-call-to-action",
    templateUrl: "./call-to-action.component.html",
    styleUrls: ["./call-to-action.component.scss"],
})

export class CallToActionComponent {
    @Input() public callToActionData: any;
}

