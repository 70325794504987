import {ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {isPlatformBrowser} from "@angular/common";
import {interval, Subscription} from "rxjs";

@Component({
    selector: "org-image-slider",
    templateUrl: "./image-slider.component.html",
    styleUrls: ["./image-slider.component.scss"],
    changeDetection: ChangeDetectionStrategy.Default,
    animations: [
        trigger("slide", [
            state("1", style({ transform: "translateX(0)" })),
            state("2", style({ transform: "translateX(-100%)" })),
            state("3", style({ transform: "translateX(-200%)" })), // Can be expanded for more than 3
            transition("* => *", animate(200)),
        ])
    ],
})

export class ImageSliderComponent implements OnInit, OnDestroy {
    @Input() public imageSliderData: any;
    public switch = "consumer";
    public currentSlide = 1;
    public slideCount = 1;
    public showImages = false;

    private slideInterval_: Subscription;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {}

    ngOnInit(): void {
        this.slideCount = this.imageSliderData[this.switch].length;
        if (isPlatformBrowser(this.platformId)) {
            this.slideInterval_ = interval(8000).subscribe(val => {
                this.nextSlide();
            });
        }
    }

    ngOnDestroy(): void {
        this.removeInterval();
    }

    previousSlide(): void {
        this.removeInterval();
        this.currentSlide = this.currentSlide > 1 ? this.currentSlide - 1 : 1;
    }

    // nextSlide() is triggered by subscription or by user.
    // Therefore, we must distinguish who triggered the action.
    nextSlide(byUser: boolean = false): void {
        if (byUser) {
            this.removeInterval();
        }
        this.currentSlide = this.currentSlide < this.slideCount ? this.currentSlide + 1 : 1;
    }

    removeInterval(): void {
        if (this.slideInterval_) {
            this.slideInterval_.unsubscribe();
        }
    }
}
