import { OrderResponse } from "./../../../new/order";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Provider, ProvidersApi } from "@rem/provider";
import { Region, RegionsApi } from "@rem/regions";
import { Region as CurrentRegion } from "@rem/region";
import { City, CityApi, CityWithStreets } from "@rem/city";
import { initInputs, initDivs, initSelects, initFieldsets, initSections } from "./ova-form.elements";
import { Order, Payment, StartingType, AlternativeBillingAddress, OrderApi } from "@rem/order";
import { Salutation } from "@rem/customer";
import { Step } from "@p2p/molecules/progress-bar/progress-bar.component";
import { Router, ActivatedRoute, Params } from "@angular/router";
import * as iban from "iban";
import { CustomerService } from "@rem/customer-service";
import { API_ROOT_URL } from "@rem/env";

@Component({
	selector: "org-ova-form",
	templateUrl: "./ova-form.component.html",
	styleUrls: ["./ova-form.component.scss"]
})
export class OvaFormComponent implements OnInit, OnDestroy {
	@Input() globalData: any;
	@Input() editorialData: any;

	isWaiting = false;
	currentStep = 1 as Step;
	pathname = [location.pathname];
	currentRegion = CurrentRegion.getCurrent();

	providers: Provider[];
	region: Region;

	cities: City[];
	city: CityWithStreets;

	alternativeCities: City[];
	alternativeCity: CityWithStreets;

	inputs: ReturnType<typeof initInputs>;
	divs: ReturnType<typeof initDivs>;
	selects: ReturnType<typeof initSelects>;
	fieldsets: ReturnType<typeof initFieldsets>;
	sections: ReturnType<typeof initSections>;

	validationMessages: string[] = [];
	unhandledError: null | { title: string; description: string } = null;
	invalidFieldsFromApi: string[] = [];

	params: Params;

	prevCode = "";

	constructor(public router: Router, public activatedRoute: ActivatedRoute) {
		this.params = this.activatedRoute.snapshot.queryParams;
	}

	async ngOnInit() {
		this.inputs = initInputs();
		this.divs = initDivs();
		this.selects = initSelects();
		this.fieldsets = initFieldsets();
		this.sections = initSections();

		this.initCitiesAndStreets();
		this.loadFormState();

		this.onHashChange();
		window.addEventListener("hashchange", this.onHashChange.bind(this));

		let providerPromise = ProvidersApi.getAll();
		let regionPromise = RegionsApi.get(this.currentRegion);

		this.providers = await providerPromise;
		this.region = await regionPromise;

		if (this.params.code) {
			this.checkCode();
		}

		// Re-load form state once we know providers and region so the
		// respective select boxes (which include them as dynamic options)
		// will be selected properly.
		setTimeout(() => {
			this.loadFormState();
		}, 0);
	}

	ngOnDestroy() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	onHashChange() {
		let hash = location.hash;
		if (!hash) {
			// Default to step 1 if no step can be determined.
			return this.goToStep(1);
		}
		// Hash should look like `#step-1`. We're only interested
		// in the last character, as a number.
		let step = parseInt(location.hash.slice(-1)) as Step;
		this.goToStep(step, true);
	}

	initCitiesAndStreets() {
		this.cities = this.loadSessionJson("cities");
		if (this.cities) {
			this.selects.city.disabled = false;
		}
		this.city = this.loadSessionJson("city");
		if (this.city) {
			this.selects.city.disabled = false;
			this.selects.street.disabled = false;
		}
		this.alternativeCities = this.loadSessionJson("alternative_cities");
		if (this.alternativeCities) {
			this.selects.alternativeCity.disabled = false;
		}
		this.alternativeCity = this.loadSessionJson("alternative_city");
		if (this.alternativeCity) {
			this.selects.alternativeCity.disabled = false;
			this.selects.alternativeStreet.disabled = false;
		}
	}
	loadSessionJson(key: string) {
		let item = sessionStorage.getItem("ova_" + key);
		return item ? JSON.parse(item) : null;
	}
	saveSessionJson(key: string, item: any) {
		// This is not urgent work so queue for better responsiveness
		setTimeout(() => {
			sessionStorage.setItem("ova_" + key, JSON.stringify(item));
		}, 0);
	}

	/**
	 * Requirements:
	 * - Once a zipcode has been entered, the cities that belong to the specified zipcode should be suggested.
	 * - Once a city has been entered, the streets that belong to the city should be suggested.
	 * - The above points apply to the alternative billing address as well.
	 */
	async onZipcodeChange() {
		let zipcode = this.selects.zipcode.value;
		if (zipcode.length !== 5) {
			// Don't hammer API with invalid zipcodes.
			return;
		}
		if (zipcode !== "") {
			let cities = await CityApi.getAll(zipcode);
			// Uncomment the next line to test multiple cities being returned.
			// cities.push({ id: "fake", name: "Klein-Kuhkackerohde", zipCode: zipcode });
			this.cities = cities;
			this.saveSessionJson("cities", cities);
			this.selects.city.disabled = false;
			if (cities.length === 1) {
				// Field will be filled automatically so no "city input" interaction
				// will be triggered by user, so we have to trigger this manually here.
				// Since this will need to trigger another round of change detection,
				// set a timeout...
				setTimeout(() => {
					this.onCityChange();
					this.validateSelect(this.selects.city);
				}, 0);
			}
		}
	}
	async onCityChange() {
		let cityName = this.selects.city.value;
		if (cityName !== "" && this.cities !== undefined) {
			let selectedCity = this.cities.find(c => c.name === cityName);
			if (selectedCity !== undefined) {
				let city = await CityApi.getOne(selectedCity.id);
				// Uncomment the next line to test no streets being returned:
				// if (city.name === "Abensberg") city.streets = [];
				this.city = city;
				this.saveSessionJson("city", city);
				if (city.streets.length === 0) {
					this.divs.streetContainer.style.display = "none";
					this.divs.streetFallbackContainer.style.display = "block";
				} else {
					this.divs.streetContainer.style.display = "block";
					this.divs.streetFallbackContainer.style.display = "none";
					this.selects.street.disabled = false;
				}
			}
		}
	}
	async onAlternativeZipcodeInput() {
		const zipcode = this.inputs.alternativeZipcode.value;
		if (zipcode.length !== 5) {
			// Don't hammer API with invalid zipcodes.
			return;
		}
		if (zipcode !== "") {
			let alternativeCities = await CityApi.getAll(zipcode);
			// Uncomment the next line to test multiple cities being returned.
			// cities.push({ id: "fake", name: "Klein-Kuhkackerohde", zipCode: zipcode });
			this.alternativeCities = alternativeCities;
			this.saveSessionJson("alternative_cities", alternativeCities);
			this.selects.alternativeCity.disabled = false;
			if (alternativeCities.length === 1) {
				// Field will be filled automatically so no "city input" interaction
				// will be triggered by user, so we have to trigger this manually here.
				// Since this will need to trigger another round of change detection,
				// set a timeout...
				setTimeout(() => {
					this.onAlternativeCityChange();
					this.validateSelect(this.selects.alternativeCity);
				}, 0);
			}
		}
	}
	async onAlternativeCityChange() {
		const cityName = this.selects.alternativeCity.value;
		if (cityName !== "" && this.alternativeCities !== undefined) {
			let selectedCity = this.alternativeCities.find(c => c.name === cityName);
			if (selectedCity !== undefined) {
				let alternativeCity = await CityApi.getOne(selectedCity.id);
				// Uncomment the next line to test no streets being returned:
				// if (alternativeCity.name === "Abensberg") alternativeCity.streets = [];
				this.alternativeCity = alternativeCity;
				this.saveSessionJson("alternative_city", alternativeCity);
				if (alternativeCity.streets.length === 0) {
					this.divs.alternativeStreetContainer.style.display = "none";
					this.divs.alternativeStreetFallbackContainer.style.display = "block";
				} else {
					this.divs.alternativeStreetContainer.style.display = "block";
					this.divs.alternativeStreetFallbackContainer.style.display = "none";
					this.selects.alternativeStreet.disabled = false;
				}
			}
		}
	}

	/**
	 * Requirements:
	 * - The customer has recently moved or will move soon. If so, we ask for the relocation date.
	 *   The relocation date is assumed to be the delivery date, and we do not need to ask about the
	 *   cancellation status of his previous contract.
	 * - If the customer has not moved recently or will not move soon, we need to know whether the contract
	 *   has already been cancelled or whether we need to cancel for the customer.
	 * - If the contract has already been cancelled, the cancellation date is assumed to be the delivery date.
	 * - If we need to cancel for the customer, we ask the customer to provide a delivery date. The delivery
	 *   date can be either "as soon as possible" or a specific date.
	 * - In summary, we always have a delivery date. It can be in the form of the relocation date, the
	 *   cancellation date, the desired delivery date, or "as soon as possible". (It can never be multiple
	 *   of these at the same time.)
	 */
	onRelocationChange() {
		if (this.inputs.RECENTLY_RELOCATED.checked || this.inputs.WILL_RELOCATE.checked) {
			this.divs.relocationDateContainer.style.display = "block";
			this.divs.cancellationContainer.style.display = "none";
			this.sections.deliveryDateSection.style.display = "none";
			this.inputs.relocationDate.focus();
			this.validateInput(this.inputs.relocationDate);
		} else {
			this.divs.relocationDateContainer.style.display = "none";
			this.divs.cancellationContainer.style.display = "block";
			// Only show delivery date selection if not already cancelled.
			if (!this.inputs.ALREADY_CANCELLED.checked) {
				this.sections.deliveryDateSection.style.display = "block";
			}
		}
	}
	onCancellationChange() {
		if (this.inputs.ALREADY_CANCELLED.checked) {
			this.divs.cancellationDateContainer.style.display = "block";
			this.sections.deliveryDateSection.style.display = "none";
			this.divs.cancellationDisclaimer.style.display = "none";
		} else {
			this.divs.cancellationDateContainer.style.display = "none";
			this.sections.deliveryDateSection.style.display = "block";
			this.divs.cancellationDisclaimer.style.display = "block";
		}
	}
	onDeliveryChange() /* prettier-ignore */ {
		this.divs.desiredDateContainer.style.display =
						this.inputs.DELIVERY_ASAP.checked ? "none" : "block";
		if (this.inputs.DELIVERY_AT_DATE.checked) {
			this.inputs.desiredDate.focus();
		}
	}

	/**
	 * Requirements:
	 * - An alternative billing address may be entered optionally.
	 */
	onAlternativeBillingAddressChange() /* prettier-ignore */ {
		this.divs.alternativeBillingAddressContainer.style.display =
            this.inputs.alternativeBillingAddress.checked ? "block" : "none";
    }

	/**
	 * Requirements:
	 * - Customers may choose direct debit or bank transfer as a payment method.
	 */
	onPaymentChange() {
		if (this.selects.paymentType.value === "DIRECT_DEBIT") {
			this.divs.directDebitContainer.style.display = "block";
			this.divs.bankTransferContainer.style.display = "none";
		} else {
			this.divs.directDebitContainer.style.display = "none";
			this.divs.bankTransferContainer.style.display = "block";
		}
	}

	async checkCode() {
		let anchor = document.getElementById("gotostep2") as HTMLAnchorElement;
		let input = this.inputs.recruitFriends;
		let code = input.value.trim().toUpperCase();
		if (this.prevCode === code) {
			return;
		}
		this.prevCode = code;
		input.disabled = true;
		anchor.classList.add("disabled");
		anchor.classList.add("loading");
		try {
			let res = await fetch(`${API_ROOT_URL}/api/ova/recommendation/${code}`);
			if (!res.ok) {
				input.dataset.apiValidationResult = "invalid";
				input.parentElement.classList.remove("validation-success");
			} else {
				input.dataset.apiValidationResult = "valid";
				input.parentElement.classList.add("validation-success");
			}
		} catch {
			// Ignore network errors
		}
		input.disabled = false;
		anchor.classList.remove("disabled");
		anchor.classList.remove("loading");
		this.validateInput(input);
	}

	/**
	 * Requirements:
	 * - Just input validation... Inputs are validated once an input element has lost
	 *   focus rather than while typing.
	 */
	onFormFocusOut(event: Event) {
		const el = event.target;
		if (el instanceof HTMLInputElement) {
			// Radio buttons don't need to be validated - they always have a default selection.
			if (el.type !== "radio") {
				this.validateInput(el);
				this.saveFormState();
			}
			this.checkDirtyInput(el);
		}
	}

	onFormChange(event: Event) {
		const el = event.target;
		if (el instanceof HTMLInputElement) {
			if (el.type === "radio") {
				// Radio buttons don't need to be validated.
				this.saveFormState();
			}
			if (el.type === "checkbox") {
				// Checkboxes need to be validated
				this.validateInput(el);
				this.saveFormState();
			}
			this.checkDirtyInput(el);
		} else if (el instanceof HTMLSelectElement) {
			this.validateSelect(el);
			this.saveFormState();
		}
	}

	checkDirtyInput(el: HTMLInputElement) {
		if (el.value !== "") {
			el.classList.add("dirty");
		} else {
			el.classList.remove("dirty");
		}
	}

	validateInput(input: HTMLInputElement) {
		const isRequired = input.hasAttribute("required");
		const hasPattern = input.hasAttribute("pattern");

		let valid = true;
		let message = "";

		// If there is no `required` or `pattern` attribute, the input does not need
		// to be validated.
		//
		// :/
		if (!isRequired && !hasPattern && input.id !== "recruitFriends") {
			return { valid };
		}

		if (isRequired) {
			if (input.type === "checkbox") {
				// Make sure checkbox is checked
				if (!input.checked) {
					valid = false;
					message = input.dataset.requiredMessage;
				}
			} else {
				// Make sure input contains text
				if (input.value === "") {
					valid = false;
					message = input.dataset.requiredMessage;
				}
			}
		}

		if (hasPattern) {
			// There are fields that are not required, but if they are filled, they need
			// to match a certain pattern (e.g. phone number). So only validate the pattern
			// if the field is not empty.
			if (input.value !== "") {
				let regex = new RegExp(input.getAttribute("pattern"));
				if (!regex.test(input.value)) {
					valid = false;
					message = input.dataset.patternMessage;
				}
			}
		}

		if (valid) {
			if (input.id === "dateOfBirth") {
				let dateOfBirth = new Date(this.sanitizeDate(input.value));
				let eighteenthBirthday = new Date(dateOfBirth.getFullYear() + 18, dateOfBirth.getMonth(), dateOfBirth.getDate());
				if (eighteenthBirthday > new Date()) {
					valid = false;
					message = input.dataset.customMessage;
				}
			}

			if (input.id === "email") {
				// There may be differences between FE and MW email validation.
				// For example, the FE regex allows "mark.tiedemann@eon.notcom" while
				// the MW checks that the TLD actually exists.
				if (this.invalidFieldsFromApi.includes("email")) {
					valid = false;
					message = input.dataset.patternMessage;
				}
			}

			if (input.id === "iban") {
				// There may be differences between FE and MW IBAN validation.
				// For example, iban.js considers "GB2LABBY09012857201707" a valid IBAN
				// which it is not - it has an invalid checksum structure.
				// See https://www.iban.com/testibans for test IBANs.
				if (!iban.isValid(input.value) || this.invalidFieldsFromApi.includes("iban")) {
					valid = false;
					message = input.dataset.customMessage;
				}
			}

			if (input.id === "relocationDate") {
				let relocationDate = new Date(this.sanitizeDate(input.value));
				let now = new Date();
				if (this.inputs.RECENTLY_RELOCATED.checked) {
					let fourWeeksInMs = 1000 /*ms->s*/ * 60 /*s->m*/ * 60 /*m->h*/ * 24 /*h->d*/ * 7 /*d->w*/ * 4;
					let fourWeeeksAgo = new Date().getTime() - fourWeeksInMs;
					if (relocationDate.getTime() < fourWeeeksAgo || relocationDate > now) {
						valid = false;
						message = input.dataset.customMessageRecentlyRelocated;
					}
				}
				if (this.inputs.WILL_RELOCATE.checked) {
					if (relocationDate < now) {
						valid = false;
						message = input.dataset.customMessageWillRelocate;
					}
				}
			}

			// :(
			if (input.id === "recruitFriends") {
				let invalid = input.dataset.apiValidationResult === "invalid";
				if (invalid) {
					valid = false;
					message = input.dataset.customMessage;
				} else {
					if (input.parentElement.classList.contains("validation-success")) {
						message = "Ihr Werbecode ist gültig!";
					}
				}
			}
		}

		// TODO: Is order of validation error messages correct? Should more specific messages,
		// such as, messages regarding the pattern always overwrite the less specific messages,
		// such as, the "is required" messages?

		let messageContainer = // ...Oh dear.
			input.type === "checkbox" ? input.nextElementSibling.nextElementSibling : input.nextElementSibling;

		if (!valid) {
			input.parentElement.classList.add("validation-error");
			input.classList.remove("valid");
			input.classList.add("invalid");
			messageContainer.textContent = message;
			return { valid, message };
		} else {
			input.parentElement.classList.remove("validation-error");
			input.classList.remove("invalid");
			input.classList.add("valid");
			messageContainer.textContent = message;
			return { valid };
		}
	}
	validateMinAge(input: HTMLInputElement) {
		const now = new Date();
		const dateOfBirth = new Date(this.sanitizeDate(input.value));
		const eighteenthBirthday = new Date(dateOfBirth.getFullYear() + 18, dateOfBirth.getMonth(), dateOfBirth.getDate());
		return eighteenthBirthday <= now;
	}
	validateSelect(select: HTMLSelectElement) {
		if (select.value === "") {
			const message = select.dataset.requiredMessage;
			select.parentElement.classList.add("validation-error");
			select.classList.remove("valid");
			select.classList.add("invalid");
			select.nextElementSibling.textContent = message;
			return { valid: false, message };
		} else {
			select.parentElement.classList.remove("validation-error");
			select.classList.remove("invalid");
			select.classList.add("valid");
			select.nextElementSibling.textContent = "";
			return { valid: true };
		}
	}

	/**
	 * Requirements:
	 * - As a developer, I don't want to see an empty page whenever I hit save in my editor..
	 */
	saveFormState() {
		// TODO: Replace `setTimeout` with `requestIdleCallback` once it has better browser support.
		setTimeout(() => {
			let state = {
				inputs: {},
				selects: {}
			};

			for (let [key, input] of Object.entries(this.inputs)) {
				if (input.type === "radio" || input.type === "checkbox") {
					state.inputs[key] = input.checked ? "checked" : "";
				} else {
					state.inputs[key] = input.value;
				}
			}
			for (let [key, input] of Object.entries(this.selects)) {
				state.selects[key] = input.value;
			}
			sessionStorage.setItem("ova_form_state", JSON.stringify(state));
		}, 0);
	}
	loadFormState() {
		const item = sessionStorage.getItem("ova_form_state");

		if (!item) {
			return;
		}

		let state: {
			inputs: Record<string, string>;
			selects: Record<string, string>;
		} = JSON.parse(item);

		for (let [key, val] of Object.entries(state.inputs)) {
			let el = this.inputs[key] as HTMLInputElement;
			if (el) {
				if (el.type === "radio" || el.type === "checkbox") {
					el.checked = val === "checked";
				} else {
					el.value = val;
				}
			}
		}
		for (let [key, val] of Object.entries(state.selects)) {
			let el = this.selects[key] as HTMLSelectElement;
			if (el) {
				el.value = val;
				// If the form was filled-in with values from a previous session
				// such as after a reload, we'll trigger a change event on select boxes.
				el.dispatchEvent(new Event("change"));
			}
		}
		// Since loading the state affects whether certain sections of the form
		// should be visible or hidden, we need to manually trigger a re-computation
		// of which parts of the form are visible or hidden.
		this.onFormStateChange();
	}
	onFormStateChange() {
		// These need to be called in opposite order of how they are defined
		// since relocation changes overwrite cancellation changes which in
		// turn overwrite delivery changes.
		this.onDeliveryChange();
		this.onCancellationChange();
		this.onRelocationChange();

		this.onAlternativeBillingAddressChange();
		this.onPaymentChange();
	}

	goToStep2() {
		(async () => {
			await this.checkCode();
			this.goToStep(2);
		})();
	}

	goToStep(step: Step, skipValidation = false) {
		if (!skipValidation) {
			if (!this.validateForm()) {
				// Reset router navigation
				this.router.navigate(this.pathname, { fragment: "step-" + this.currentStep, preserveQueryParams: true });
				return;
			}
		}

		this.currentStep = step;

		// For the step 3, both the 1st and the 2nd fieldset need to be visible
		// so customers can review their inputs.
		this.fieldsets["step-1"].style.display = step === 1 || step === 3 ? "block" : "none";
		this.fieldsets["step-2"].style.display = step === 2 || step === 3 ? "block" : "none";
		this.fieldsets["step-3"].style.display = step === 3 ? "block" : "none";

		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: "smooth" });
			// Timeout of a few ms is necessary here so Angular has time to re-render the fieldsets.
			// Otherwise, when clicking "previous step", scrolling may not reach the top of the screen
			// as the top part of the form won't have been rendered yet.
		}, 300);
	}

	validateForm() {
		const messages: string[] = [];
		let valid = true;

		for (let select of Object.values(this.selects)) {
			// See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
			const isVisible = select.offsetParent !== null;
			if (isVisible) {
				let validation = this.validateSelect(select);
				if (!validation.valid) {
					messages.push(validation.message);
					valid = false;
				}
			}
		}

		for (let input of Object.values(this.inputs)) {
			const isVisible = input.offsetParent !== null;
			if (isVisible) {
				let validation = this.validateInput(input);
				if (!validation.valid) {
					messages.push(validation.message);
					valid = false;
				}
			}
		}

		this.validationMessages = messages;

		return valid;
	}

	sanitizeDate(value: string) {
		const isGermanDateFormat = value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/);
		if (isGermanDateFormat) {
			let [day, month, year] = value.split(".");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		} else {
			return value;
		}
	}

	async onOrderSubmit() {
		if (!this.validateForm()) {
			return;
		}

		this.isWaiting = true;

		const payment = (): Payment => {
			let value = this.selects.paymentType.value;
			// prettier-ignore
			switch (value) {
				case "BANK_TRANSFER": return null; // `null` represents "bank transfer" in the API
				case "DIRECT_DEBIT": return {
					bankAccount: {
						iban: this.inputs.iban.value,
						owner: this.inputs.accountOwner.value
					}
				};
				default: throw new Error(`Unknown paymentType '${value}'`);
			}
		};

		// prettier-ignore
		const startingType = (): StartingType => {
			const RELOCATION_AT = this.inputs.WILL_RELOCATE.checked || this.inputs.RECENTLY_RELOCATED.checked;
			if (RELOCATION_AT) return {
				startingType: "RELOCATION_AT",
				date: this.sanitizeDate(this.inputs.relocationDate.value),
			};
			const TERMINATED_IN_PERSON = this.inputs.ALREADY_CANCELLED.checked;
			if (TERMINATED_IN_PERSON) return {
				startingType: "TERMINATED_IN_PERSON",
				date: this.sanitizeDate(this.inputs.cancellationDate.value)
			};
			const DESIRED_DATE = this.inputs.DELIVERY_AT_DATE.checked;
			if (DESIRED_DATE) return {
				startingType: "DESIRED_DATE",
				date: this.sanitizeDate(this.inputs.desiredDate.value)
			};
			// Be nice to the customer: Default to EARLIEST_POSSIBLE_DATE
			// rather than throwing an error if no case matches.
			return {
				startingType: "EARLIEST_POSSIBLE_DATE"
			};
		};

		const hasUnknownAddress = this.city.streets.length === 0;
		const hasUnknownAlternativeAddress = this.alternativeCity ? this.alternativeCity.streets.length === 0 : false;

		const alternativeBillingAddress = (): null | AlternativeBillingAddress => {
			if (!this.inputs.alternativeBillingAddress.checked) {
				return null;
			}
			return {
				city: this.selects.alternativeCity.value,
				contact: {
					companyName: this.inputs.alternativeCompanyName.value === "" ? null : this.inputs.alternativeCompanyName.value,
					firstname: this.inputs.alternativeFirstname.value,
					lastname: this.inputs.alternativeLastname.value,
					salutation: this.selects.alternativeSalutation.value as Salutation
				},
				houseNumber: this.inputs.alternativeHouseNumber.value,
				street: hasUnknownAlternativeAddress
					? this.inputs.alternativeStreetFallback.value
					: this.selects.alternativeStreet.value,
				zipcode: this.inputs.alternativeZipcode.value
			};
		};

		const previousProviderBdewId = (): string => {
			let value = this.selects.provider.value;
			let provider = this.providers.find(p => p.name === value);
			return provider.bdewId;
		};

		const recommendationCode = (): string | null => {
			let code = this.inputs.recruitFriends.value.trim().toUpperCase();
			if (code === "") return null;
			else return code;
		};

		const order: Order = {
			address: {
				city: this.selects.city.value,
				houseNumber: this.inputs.houseNumber.value,
				street: hasUnknownAddress ? this.inputs.streetFallback.value : this.selects.street.value,
				zipcode: this.selects.zipcode.value
			},
			adsMail: this.inputs.adsMail.checked,
			adsPhone: this.inputs.adsPhone.checked,
			adsPost: this.inputs.adsPost.checked,
			consumption: parseInt(this.params.consumption),
			contact: {
				dateOfBirth: this.sanitizeDate(this.inputs.dateOfBirth.value),
				companyName: this.inputs.companyName.value === "" ? null : this.inputs.companyName.value,
				email: this.inputs.email.value,
				firstname: this.inputs.firstname.value,
				lastname: this.inputs.lastname.value,
				phone: this.inputs.phone.value,
				salutation: this.selects.salutation.value as Salutation
			},
			deviceNumber: this.inputs.deviceNumber.value,
			payment: payment(),
			productCode: this.params.product,
			regionId: this.region.id,
			startingType: startingType(),
			unknownAddress: hasUnknownAddress || hasUnknownAlternativeAddress,
			alternativeBillingAddress: alternativeBillingAddress(),
			previousProviderBdewId: previousProviderBdewId(),
			referralBillboards: this.inputs.referralBillboards.checked,
			referralFriends: this.inputs.referralFriends.checked,
			referralInternet: this.inputs.referralInternet.checked,
			referralNewspaper: this.inputs.referralNewspaper.checked,
			referralOthers: this.inputs.referralOthers.checked,
			referralOthersText: this.inputs.referralOthersText.value,
			recommendationCode: recommendationCode(),
			tosAccepted: this.inputs.termsAndConditions.checked,
		};

		const res: OrderResponse = await OrderApi.post(order);
		this.isWaiting = false;

		switch (res) {
			case "OK":
				this.router.navigate(this.pathname, { fragment: "erfolgreichem-abschluss" });
				return;
			case "ACCEPTED":
				this.router.navigate(this.pathname, { fragment: "erfolgreichem-abschluss-no-confirmation" });
				return;
			case "UNKNOWN_ERROR":
				this.unhandledError = {
					title: "Es tut uns Leid, da ist etwas schief gelaufen.",
					description: this.formatErrorDescription()
				};
				return;
			case "ORDER_CUSTOMER_EXISTING_NOT_IDENTICAL":
				this.unhandledError = {
					title: "Es existiert bereits ein Kundenkonto zur angegebenen E-Mail-Adresse.",
					description: this.formatErrorDescriptionForSecondContract()
				};
				return;
			default:
				this.invalidFieldsFromApi = res.invalidFields;
				this.validateForm();
				this.invalidFieldsFromApi = [];
				return;
		}
	}

	formatErrorDescriptionForSecondContract() {
		let { email, isOpen, supportNumber } = CustomerService(this.currentRegion);
		let phoneAvailability = isOpen()
			? /*html*/ `unter <a href="tel:${supportNumber.value}">${supportNumber.displayValue}</a> oder `
			: "";
		return /*html*/ `<p>Bitte prüfen Sie nochmals Ihre Daten. Bei weiteren Verträgen müssen Name und Geburtsdatum identisch sein.</p>
<p>Bei weiteren Fragen hilft Ihnen unser Kundenservice sehr gerne weiter.</p>
<p>Sie erreichen uns ${phoneAvailability}per Mail (<a href="mailto:${email}">${email}</a>).</p>`;
	}

	formatErrorDescription() {
		let { email, isOpen, supportNumber } = CustomerService(this.currentRegion);
		let phoneAvailability = isOpen()
			? /*html*/ `unter <a href="tel:${supportNumber.value}">${supportNumber.displayValue}</a> oder `
			: "";
		return /*html*/ `<p>Bitte prüfen Sie nochmals Ihre Daten.</p>
<p>Bei weiteren Fragen hilft Ihnen unser Kundenservice sehr gerne weiter.</p>
<p>Sie erreichen uns ${phoneAvailability}per Mail (<a href="mailto:${email}">${email}</a>).</p>`;
	}

	preisUndLieferbedingungen() {
		// if (this.currentRegion === "abensberg") {
		//   if (this.params.product === "033" /*FLEX*/)
		//	   return "./assets/editorial/abensberg/media/downloads/Ihr_Auftrag_Naturstrom_FLEX.pdf";
		//	 if (this.params.product === "028" /*FIX*/)
		//     return "./assets/editorial/abensberg/media/downloads/Ihr_Auftrag_Naturstrom_FIX.pdf";
		// }
		return `./assets/editorial/${this.currentRegion}/media/downloads/Preis-und-Lieferbedingungen.pdf`;
	}
}
