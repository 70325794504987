<div class="container-fluid">
    <div class="image-slider lazyload-trigger" [preRender]="false" (deferLoad)="showImages=true">
        <section>
            <article>
                <div class="row">
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-6 col-xl-push-1 col-lg-push-1 col-md-push-1 ">
                        <div class="image-slider__text">
                            <div class="image-slider__text__wrapper">
                                <div class="image-slider__text__container">
                                    <div *ngFor="let slide of imageSliderData[switch]; let i = index" [@slide]="currentSlide">
                                        <div class="image-slider__text-tophead">
                                            <p [innerHTML]="slide.tophead"></p>
                                        </div>
                                        <div class="image-slider__text-mainhead">
                                            <h2 [innerHTML]="slide.mainhead"></h2>
                                        </div>
                                        <div class="image-slider__text-box">
                                            <div class="image-slider__text-counter">
                                                <div class="image-slider__text-counter-start">{{ i + 1 }}</div>
                                                <div class="image-slider__text-counter-end">{{ slideCount }}</div>
                                            </div>
                                            <p [innerHTML]="slide.text"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="image-slider__controls">
                                <button class="image-slider__controls-left" [disabled]="currentSlide === 1" (click)="previousSlide()" [ngClass]="{'active': currentSlide !== 1}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                        <path fill="#F39501" fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
                                    </svg>
                                </button>
                                <button class="image-slider__controls-right" [disabled]="currentSlide === this.slideCount" (click)="nextSlide(true)" [ngClass]="{'active': currentSlide !== this.slideCount}">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                        <path fill="#F39501" fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-5 col-xl-push-1">
                        <div class="image-slider__image__wrapper">
                            <div class="image-slider__image__container">
                                <div *ngFor="let slide of imageSliderData[switch]; let i = index" [@slide]="currentSlide">
                                    <ato-image *ngIf="showImages" class="lazyload" [image]="slide.image"></ato-image>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </div>
</div>
