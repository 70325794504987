<div class="pageFooter" [ngClass]="{ 'has-cookiedisclaimer': hasCookieDisclaimer === true }">
	<div class="pageFooter__container">
		<div class="pageFooter__linkContainer">
			<a
				*ngIf="this.hasTellAFriendSection()"
				[href]="'/' + this.whitelabel + '#tell-a-friend'"
				(click)="this.scrollToTellAFriend()"
				class="pageFooter__link"
				rel="noopener"
			>
				Freunde werben
			</a>
			<a [routerLink]="['/' + this.whitelabel, 'faq']" routerLinkActive="active" class="pageFooter__link" rel="noopener"
				>FAQ</a
			>
			<a
				[routerLink]="['/' + this.whitelabel, 'kontaktformular']"
				routerLinkActive="active"
				class="pageFooter__link"
				rel="noopener"
				>Kontakt</a
			>
			<a [routerLink]="['/' + this.whitelabel, 'agb']" routerLinkActive="active" class="pageFooter__link" rel="noopener"
				>AGB</a
			>
			<a
				href="./assets/globals/media/downloads/Stromkennzeichnung_LEW.pdf"
				download
				class="pageFooter__link"
				title="Kennzeichnung der Stromlieferungen"
				>Stromkennzeichnung</a
			>
			<a
				[routerLink]="['/' + this.whitelabel, 'impressum']"
				routerLinkActive="active"
				class="pageFooter__link"
				rel="noopener"
				>Impressum</a
			>
			<a
				[routerLink]="['/' + this.whitelabel, 'datenschutz']"
				routerLinkActive="active"
				class="pageFooter__link"
				rel="noopener"
				>Datenschutz</a
			>
		</div>
		<div class="pageFooter__copyright">© 2020 LEW AG</div>
	</div>
</div>
