import {Component, ElementRef, Input, ViewChild} from "@angular/core";

@Component({
    selector: "tpl-white-label-erzeuger",
    templateUrl: "./white-label-erzeuger-template.component.html",
    styleUrls: ["./white-label-erzeuger-template.component.scss"],
})

export class WhiteLabelErzeugerTemplateComponent {
    @Input() public globalData: any; // Keep as any for --AOT
    @Input() public editorialData: any; // Keep as any for --AOT
    @Input() public whitelabel: string;
    @ViewChild("priceCalculatorAndProductSummary", {static: false}) public priceCalculatorAndProductSummary: ElementRef;
    @ViewChild("toggleJumpTarget", {static: false}) public toggleJumpTarget: ElementRef;
    public summaryPrice: number;
    public basePrice: number;
    public workPrice: number;
    public consumption: number;
    public calculatedCo2: number;
    public pageUrl = "";

    getSummaryPrice(price: number): void {
        this.summaryPrice = price;
        this.priceCalculatorAndProductSummary.nativeElement.classList.toggle("flipped");
    }

    getBasePrice(price: number): void {
        this.basePrice = price;
    }

    getWorkPrice(price: number): void {
        this.workPrice = price;
    }

    getConsumption(value: number): void {
        this.consumption = value;
    }

    getCalculatedCo2(value: number): void {
        this.calculatedCo2 = value;
    }

    getToggle(): void {
        this.priceCalculatorAndProductSummary.nativeElement.classList.toggle("flipped");
    }
}
