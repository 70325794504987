import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {SeoService} from "@p2p/services/seo.service";
import {DataApi} from "@rem/data";

@Component({
    selector: "pag-white-label-erzeuger",
    templateUrl: "./white-label-erzeuger-page.component.html",
    styleUrls: ["./white-label-erzeuger-page.component.scss"],
})
export class WhiteLabelErzeugerPageComponent implements OnInit, OnDestroy {
    public globalData: object;
    public editorialData: any;
    public showTemplateGlobal = false;
    public showTemplateEditorial = false;
    public whitelabel: string;

    private routeParams_: Subscription;

    constructor(
        private route: ActivatedRoute,
        private seoService: SeoService
    ) { }

    async ngOnInit(): Promise<void> {
        this.routeParams_ = this.route.params.subscribe(async params => {
            this.whitelabel = params["region"];

            this.editorialData = await DataApi.getRegionData(this.whitelabel);

            if (this.editorialData && this.editorialData.pageMetas) {
                this.seoService.setTitle(this.editorialData.pageMetas.producerPage.title);
                this.seoService.updateMetaTags(this.editorialData.pageMetas.producerPage.metaData);
            }
            this.showTemplateEditorial = true;
        });

        this.globalData = await DataApi.getGlobalData();
        this.showTemplateGlobal = true;
    }

    ngOnDestroy(): void {
        this.routeParams_.unsubscribe();
    }
}
