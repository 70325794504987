import {Component, Input} from "@angular/core";

export interface ICtaButton {
    label: string;
    clickEvent: Function;
    cssClass?: string;
    showIcon?: boolean;
}

@Component({
    selector: "ato-cta-button",
    templateUrl: "./cta-button.component.html",
    styleUrls: ["./cta-button.component.scss"],
})
export class CtaButtonComponent {
    @Input() public ctaConfig: ICtaButton;
}
