<div class="container">
    <div class="toggle">
        <!-- optional class "active"-->
        <ng-container *ngIf="!customer">
            <a class="toggle-item" [class.active]="(isCustomerHover || (customer && !isProducerHover) )" [routerLink]="['/' + whitelabel]" (touchstart)="touchstartToClick($event)" (mouseenter)="isCustomerHover = true;" (mouseleave)="isCustomerHover = false;">
                <ng-container *ngTemplateOutlet="customerButton"></ng-container>
            </a>
        </ng-container>
        <ng-container *ngIf="customer">
            <button type="button" class="toggle-item" [class.active]="(isCustomerHover || (customer && !isProducerHover) )" (click)="scrollToPriceCalculator()" (touchstart)="touchstartToClick($event)" (mouseenter)="isCustomerHover = true;" (mouseleave)="isCustomerHover = false;">
                <ng-container *ngTemplateOutlet="customerButton"></ng-container>
            </button>
        </ng-container>
        <!-- optional class "active"-->
        <ng-container *ngIf="customer">
            <a class="toggle-item" [class.active]="(isProducerHover || (!customer && !isCustomerHover) )" [routerLink]="['/' + whitelabel, pageUrl]" (click)="isProducerHover = false;" (touchstart)="touchstartToClick($event)" (mouseenter)="isProducerHover = true;" (mouseleave)="isProducerHover = false;">
                <ng-container *ngTemplateOutlet="producerButton"></ng-container>
            </a>
        </ng-container>
        <ng-container *ngIf="!customer">
            <button class="toggle-item" [class.active]="(isProducerHover || (!customer && !isCustomerHover) )" (click)="scrollToProducerForm()" (touchstart)="touchstartToClick($event)" (mouseenter)="isProducerHover = true;" (mouseleave)="isProducerHover = false;">
                <ng-container *ngTemplateOutlet="producerButton"></ng-container>
            </button>
        </ng-container>
    </div>
</div>
<ng-template #customerButton>
    <p class="toggle-item__headline no-hyphens">
        <span [innerHTML]="switchData.consumer.text1"></span> <strong> {{ switchData.consumer.textHighlight }} </strong>
        <span [innerHTML]="switchData.consumer.text2"></span>
    </p>
    <div class="toggle-item__link">
        <span>Tarif berechnen</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
        </svg>
    </div>
    <div class="toggle-item__mobile" [innerHTML]="switchData.consumer.textMobile"></div>
</ng-template>
<ng-template #producerButton>
    <p class="toggle-item__headline no-hyphens">
        <span [innerHTML]="switchData.producer.text1"></span> <strong> {{ switchData.producer.textHighlight }} </strong>
        <span [innerHTML]="switchData.producer.text2"></span>
    </p>
    <div class="toggle-item__link">
        <span [innerHTML]="switchData.producer.cta"></span>
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
        </svg>
    </div>
    <div class="toggle-item__mobile" [innerHTML]="switchData.producer.textMobile"></div>
</ng-template>
