import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "org-image-teaser",
    templateUrl: "./image-teaser.component.html",
    styleUrls: ["./image-teaser.component.scss"]
})

export class ImageTeaserComponent implements OnInit {
    @Input() public imageTeaserData: any;
    public showTemplate = false;
    public showImages = false;

    ngOnInit(): void {
        this.showTemplate = true;
    }
}
