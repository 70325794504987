/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./call-to-action.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../atoms/text-paragraph/text-paragraph.component.ngfactory";
import * as i3 from "../../atoms/text-paragraph/text-paragraph.component";
import * as i4 from "../../atoms/cta-link/cta-link.component.ngfactory";
import * as i5 from "../../atoms/cta-link/cta-link.component";
import * as i6 from "@angular/common";
import * as i7 from "./call-to-action.component";
var styles_CallToActionComponent = [i0.styles];
var RenderType_CallToActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallToActionComponent, data: {} });
export { RenderType_CallToActionComponent as RenderType_CallToActionComponent };
function View_CallToActionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-text-paragraph", [], null, null, null, i2.View_TextParagraphComponent_0, i2.RenderType_TextParagraphComponent)), i1.ɵdid(1, 49152, null, 0, i3.TextParagraphComponent, [], { text: [0, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.callToActionData.text; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CallToActionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-cta-link", [], null, null, null, i4.View_CtaLinkComponent_0, i4.RenderType_CtaLinkComponent)), i1.ɵdid(1, 49152, null, 0, i5.CtaLinkComponent, [], { ctaConfig: [0, "ctaConfig"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CallToActionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "cta-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CallToActionComponent_4)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.callToActionData.buttons; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CallToActionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "landingpage-cta"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CallToActionComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CallToActionComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.callToActionData && _co.callToActionData.text); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.callToActionData && _co.callToActionData.buttons); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CallToActionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CallToActionComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.callToActionData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CallToActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mol-call-to-action", [], null, null, null, View_CallToActionComponent_0, RenderType_CallToActionComponent)), i1.ɵdid(1, 49152, null, 0, i7.CallToActionComponent, [], null, null)], null, null); }
var CallToActionComponentNgFactory = i1.ɵccf("mol-call-to-action", i7.CallToActionComponent, View_CallToActionComponent_Host_0, { callToActionData: "callToActionData" }, {}, []);
export { CallToActionComponentNgFactory as CallToActionComponentNgFactory };
