import {Component, Input} from "@angular/core";

export interface ICtaLink {
    label: string;
    link: string;
    shouldOpenInNewTab?: boolean;
    cssClass?: string;
    title?: string;
}

@Component({
    selector: "ato-cta-link",
    templateUrl: "./cta-link.component.html",
    styleUrls: ["./cta-link.component.scss"],
})
export class CtaLinkComponent {
    @Input() public ctaConfig: ICtaLink;
}
