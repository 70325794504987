<main class="loginTemplate">
    <!--insert header here-->
    <div class="loginTemplate__header">
        <org-header [whitelabel]="whitelabel"></org-header>
    </div>

    <div class="loginTemplate__subheader">
        <org-subheader [subheaderData]="globalData.subheader"></org-subheader>
    </div>

    <section class="loginTemplate__login">
        <org-login *ngIf="!urlPath || urlPath === 'login'" [whitelabel]="whitelabel" [loginData]="globalData.login" [errorMessages]="globalData.errorMessages"></org-login>
        <org-password-reset *ngIf="urlPath === 'passwort-hilfe'" [whitelabel]="whitelabel" [passwordResetData]="globalData.passwordReset" [errorMessages]="globalData.errorMessages"></org-password-reset>
        <org-password-reset-set *ngIf="urlPath === 'passwort-aendern'" [whitelabel]="whitelabel" [passwordResetSetData]="globalData.passwordResetSet" [errorMessages]="globalData.errorMessages"></org-password-reset-set>
    </section>

    <!--insert regional content here -->
    <div class="loginTemplate__regionalContent">
        <org-regional-content [headlineVisible]="false"></org-regional-content>
    </div>

</main>

<footer class="loginTemplate__footer">
    <org-footer [whitelabel]="whitelabel"></org-footer>
</footer>
