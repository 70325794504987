import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RegExpConstants } from "@p2p/constants/constants";
import { DataApi } from "@rem/data";
import { ProducersApi, ProducerInterest } from "@rem/producers";

@Component({
	selector: "org-contact-form",
	templateUrl: "./contact-form.component.html",
	styleUrls: ["./contact-form.component.scss"]
})
export class ContactFormComponent implements OnInit {
	@Input() public whitelabel: string;
	@Input() public globalData: any;
	public contactFormData: any;
	public formData: any;
	public errorMessages: any;
	public contactError = false;
	public contactCreated = false;
	public productCode: string;
	public overwriteData: any;
	isWaiting = false;

	public contactForm: FormGroup = new FormGroup({
		salutation: new FormControl("", Validators.required),
		firstname: new FormControl("", [Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
		lastname: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
		street: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
		houseNumber: new FormControl("", [Validators.required]),
		zipcode: new FormControl("", [
			Validators.required,
			Validators.minLength(5),
			Validators.maxLength(5),
			Validators.pattern(RegExpConstants.JUST_INT_REGEXP)
		]),
		city: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.SIMPLE_TEXT_REGEXP)]),
		email: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.EMAIL_REGEXP)]),
		phoneNumber: new FormControl("", [Validators.pattern(RegExpConstants.PHONE_NUMBER_REGEXP)]),
		tosAccepted: new FormControl("", [Validators.requiredTrue]),
		assumedAnnualGeneration: new FormControl("", [
			Validators.required,
			Validators.pattern(RegExpConstants.JUST_INT_REGEXP)
		]),
		productionType: new FormControl("", [Validators.required]),
		yearOfCommissioning: new FormControl("", [
			// Matches empty string (since field is not required) or 4-digit year
			Validators.pattern(/^(\d{4})?$/g)
		]),
		installedCapacity: new FormControl("", [Validators.required, Validators.pattern(RegExpConstants.JUST_INT_REGEXP)])
	});

	async ngOnInit(): Promise<void> {
		this.contactFormData = this.globalData.contactForm;
		this.formData = this.globalData.ovaForm;
		this.errorMessages = this.globalData.errorMessages;

		this.overwriteData = await DataApi.getRegionData(this.whitelabel);
		this.contactFormData.legalDisclaimer = this.overwriteData.contactForm.legalDisclaimer;
	}

	async onSubmit(): Promise<void> {
		this.isWaiting = true;

		const assumedAnnualGenerationNumber = parseFloat(this.contactForm.controls["assumedAnnualGeneration"].value);
		const installedCapacityNumber = parseFloat(this.contactForm.controls["installedCapacity"].value);

		this.contactForm.controls["assumedAnnualGeneration"].setValue(assumedAnnualGenerationNumber);
		this.contactForm.controls["installedCapacity"].setValue(installedCapacityNumber);

		try {
            let producerInterest: ProducerInterest = {
                regionId: this.whitelabel,
                ...this.contactForm.value as ProducerInterest
            };
            await ProducersApi.post(producerInterest);
            this.contactCreated = true;
        } catch {
            this.contactError = true;
		}
		this.isWaiting = false;
	}

	navigateBack(): void {
		this.contactCreated = false;
		this.contactForm.reset();
	}
}
