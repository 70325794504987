<div class="contactForm">
    <div class="container">
        <div class="row">
            <div class="col-xs-4 col-xl-10 col-xl-push-1">
                <form class="contactForm__form" [formGroup]="contactForm" (ngSubmit)="onSubmit()" #multiStepForm="ngForm">
                    <p class="contactForm__headline" [innerHTML]="contactFormData.headline"></p>
                    <div class="contactForm__form__item">
                        <label class="contactForm__form__label" [innerHTML]="formData.salutation.label"></label>
                        <div class="contactForm__form__radio">
                            <input type="radio" name="salutation" value="MISS" id="salutation_ms" formControlName="salutation">
                            <span class="contactForm__form__radio__icon"></span>
                            <label class="contactForm__form__radio__label" for="salutation_ms" [innerHTML]="formData.salutation.female"></label>
                        </div>
                        <div class="contactForm__form__radio">
                            <input type="radio" name="salutation" value="MISTER" id="salutation_mr" formControlName="salutation">
                            <span class="contactForm__form__radio__icon"></span>
                            <label class="contactForm__form__radio__label" for="salutation_mr" [innerHTML]="formData.salutation.male"></label>
                        </div>
                        <div class="contactForm__form__error" *ngIf="contactForm.get('salutation').invalid && contactForm.get('salutation').touched">
                            {{ errorMessages.validation.salutation }}
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xs-4 col-md-4 col-lg-4 col-xl-6">
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label" for="firstname" [innerHTML]="formData.name.firstName"></label>
                                    <input type="text" id="firstname" formControlName="firstname">
                                    <div class="contactForm__form__error" *ngIf="contactForm.get('firstname').invalid && contactForm.get('firstname').touched">{{errorMessages.validation.firstName }}</div>
                                </div>
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4 col-xl-6">
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label" for="lastname" [innerHTML]="formData.name.lastName"></label>
                                    <input type="text" id="lastname" formControlName="lastname">
                                    <div class="contactForm__form__error" *ngIf="contactForm.get('lastname').invalid && contactForm.get('lastname').touched">{{errorMessages.validation.lastName }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xs-4 col-md-4 col-lg-4 col-xl-6">
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label" for="email" [innerHTML]="formData.contact.email"></label>
                                    <input type="email" id="email" formControlName="email">
                                    <div class="contactForm__form__error" *ngIf="contactForm.get('email').invalid && contactForm.get('email').touched">{{errorMessages.validation.email }}</div>
                                </div>
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4 col-xl-6">
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label phone" for="phone" [innerHTML]="formData.contact.labelPhone"></label>
                                    <input type="phone" id="phone" formControlName="phoneNumber">
                                    <div class="contactForm__form__error" *ngIf="contactForm.get('phoneNumber').invalid && contactForm.get('phoneNumber').touched">{{errorMessages.validation.phone }}</div>
                                    <div class="contactForm__form__input-hint" [innerHTML]="contactFormData.phoneHint"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="contactForm__headline" [innerHTML]="contactFormData.headline2"></p>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xs-4 col-md-4 col-lg-4 col-xl-6">
                                <div class="contactForm__form__item">
                                    <div class="row">
                                        <div class="col-xs-3">
                                            <div class="contactForm__form__label" for="street" [innerHTML]="formData.address.street"></div>
                                            <input type="text" formControlName="street" id="street" class="street">
                                            <div class="contactForm__form__error" *ngIf="contactForm.get('street').invalid && contactForm.get('street').touched">{{errorMessages.validation.street }}</div>
                                        </div>
                                        <div class="col-xs-1">
                                            <div class="contactForm__form__label" for="housenumber" [innerHTML]="formData.address.streetNumber"></div>
                                            <input type="text" formControlName="houseNumber" id="housenumber" class="housenumber">
                                            <div class="contactForm__form__error" *ngIf="contactForm.get('houseNumber').invalid && contactForm.get('houseNumber').touched">{{errorMessages.validation.streetNumber }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label" for="assumedAnnualGeneration" [innerHTML]="contactFormData.assumedAnnualGenerationLabel"></label>
                                    <input type="text" id="assumedAnnualGeneration" formControlName="assumedAnnualGeneration">
                                    <div class="contactForm__form__error" *ngIf="contactForm.get('assumedAnnualGeneration').invalid && contactForm.get('assumedAnnualGeneration').touched">{{errorMessages.validation.assumedAnnualGeneration }}</div>
                                </div>
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label" for="yearOfCommissioning" [innerHTML]="contactFormData.yearOfCommissioning"></label>
                                    <input type="text" [min]="4" [max]="4" id="yearOfCommissioning" formControlName="yearOfCommissioning">
                                    <div class="contactForm__form__error" *ngIf="contactForm.get('yearOfCommissioning').invalid && contactForm.get('yearOfCommissioning').touched">{{errorMessages.validation.yearOfCommissioning }}</div>
                                </div>
                            </div>
                            <div class="col-xs-4 col-md-4 col-lg-4 col-xl-6">
                                <div class="contactForm__form__item">
                                    <div class="row">
                                        <div class="col-xs-3">
                                            <div class="contactForm__form__label" for="city" [innerHTML]="formData.address.city"></div>
                                            <input type="text" id="city" class="city" formControlName="city">
                                            <div class="contactForm__form__error" *ngIf="contactForm.get('city').invalid && contactForm.get('city').touched">{{errorMessages.validation.city }}</div>
                                        </div>
                                        <div class="col-xs-1">
                                            <div class="contactForm__form__label" for="zip" [innerHTML]="formData.address.zip"></div>
                                            <input type="text" id="zip" class="zip" formControlName="zipcode">
                                            <div class="contactForm__form__error" *ngIf="contactForm.get('zipcode').invalid && contactForm.get('zipcode').touched">{{errorMessages.validation.zip }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label" for="productionType" [innerHTML]="contactFormData.productionType"></label>
                                    <div class="contactForm__form__select">
                                        <select name="productionType" id="productionType" formControlName="productionType">
                                            <option value="" disabled [innerHTML]="contactFormData.productionTypeInitial"></option>
                                            <option *ngFor="let type of contactFormData.productionTypeArray" [value]="type.value" [innerHTML]="type.name"></option>
                                        </select>
                                    </div>
                                    <br>
                                </div>
                                <div class="contactForm__form__item">
                                    <label class="contactForm__form__label" for="installedCapacity" [innerHTML]="contactFormData.installedCapacity"></label>
                                    <input type="text" id="installedCapacity" formControlName="installedCapacity">
                                    <div class="contactForm__form__error" *ngIf="contactForm.get('installedCapacity').invalid && contactForm.get('installedCapacity').touched">{{errorMessages.validation.installedCapacity }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-4 col-xl-10 col-xl-push-1">
                                <div class="contactForm__form__item">

                                        <span class="contactForm__form__checkbox">
                                                <label class="contactForm__form__checkbox__label" for="dataprotection" [innerHTML]="contactFormData.legalDisclaimer"></label>
                                                <input type="checkbox" formControlName="tosAccepted" name="tosAccepted" id="dataprotection" class="contactForm__form__accordion__opener">
                                                <span class="contactFormV2Alt__form__checkbox__icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" focusable="false">
                                                        <path fill-rule="nonzero" d="M27.639 10L29 11.347 14.11 26 7 19.033l1.358-1.35 5.75 5.634z"/>
                                                    </svg>
                                                </span>
                                            </span>
                                </div>
                                <div class="contactForm__form__center formactions">
                                    <button *ngIf="!this.isWaiting" class="button preferred" type="submit" [disabled]="contactForm.invalid" [innerHTML]="contactFormData.buttonLabel"></button>
                                    <button *ngIf="this.isWaiting" class="button preferred waiting" type="submit" [disabled]="contactForm.invalid" [innerHTML]="contactFormData.buttonLabel"></button>
                                </div>
                                <div class="contactForm__form__hint">
                                    <p [innerHTML]="contactFormData.requiredHint"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<mol-overlay *ngIf="contactCreated" [type]="'success'" [headline]="'Vielen Dank für Ihr Interesse!'" [subHeadline]="'Wir bearbeiten Ihre Anfrage und melden uns umgehend bei Ihnen.'" (onCallToAction)="navigateBack()"></mol-overlay>
<mol-overlay *ngIf="contactError" [type]="'error'" [headline]="'Es tut uns Leid, etwas ist schief gelaufen.'" [subHeadline]="'Bitte versuchen Sie es nochmal oder setzen Sie sich telefonisch mit uns in Kontakt, wir helfen Ihnen gerne weiter.'" (onCallToAction)="contactError = false"></mol-overlay>
