import {Component, Input} from "@angular/core";

@Component({
    selector: "org-producer-info",
    templateUrl: "./producer-info.component.html",
    styleUrls: ["./producer-info.component.scss"],
})

export class ProducerInfoComponent {
    @Input() public producerInfoData: any;
}
