import {Component, Input} from "@angular/core";

@Component({
    selector: "org-toggle",
    templateUrl: "./toggle.component.html",
    styleUrls: ["./toggle.component.scss"],
})

export class ToggleComponent {
    @Input() public switchData: any;
    @Input() public pageUrl: string;
    @Input() public whitelabel: string;
    @Input() public editorialData: object;
    @Input() public customer = true;
    @Input() public jumpTarget: any;

    scrollToPriceCalculator(): void {
        const priceCalculator = this.jumpTarget;
        if (window.innerHeight > priceCalculator.clientHeight) {
            priceCalculator.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
        } else {
            priceCalculator.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }

    // Fix doubleclick on ios:
    touchstartToClick(event: any): void {
        const target = event.currentTarget;
        event.preventDefault();
        target.click();
    }

    scrollToProducerForm(): void {
        const producerForm = this.jumpTarget;
        if (window.innerHeight > producerForm.clientHeight) {
            producerForm.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
            });
        } else {
            producerForm.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }
}
