<ng-container *ngIf="showTemplate">
    <div class="legalPage__header">
        <org-header [whitelabel]="whitelabel"></org-header>
    </div>

    <div class="legalPage">
        <h1 class="legalPage__heading" [innerHTML]="editorialData?.legalPage?.headline"></h1>
        <div class="legalPage__section">
            <div class="container">
                <div class="row">
                    <div class="col-xs-4 col-xl-8 col-xl-push-2">
                        <div class="legalPage__content">
                            <h3 class="legalPage__heading--small" [innerHTML]="editorialData?.legalPage?.subheadline"></h3>
                            <div class="legalPage__textarea" [innerHTML]="editorialData?.legalPage?.text_before_button"></div>
                            <ato-google-opt-out-button></ato-google-opt-out-button>
                            <div class="legalPage__textarea" [innerHTML]="editorialData?.legalPage?.text_after_button"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <org-footer [whitelabel]="whitelabel"></org-footer>
</ng-container>
