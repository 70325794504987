<main *ngIf="showTemplate" class="whitelabelTemplate">

    <div class="whitelabelTemplate__header">
        <org-header [whitelabel]="whitelabel"></org-header>
    </div>

    <section class="whitelabelTemplate__section whitelabelTemplate__ova" *ngIf="fragment !== 'erfolgreichem-abschluss'">
        <org-ova-form [globalData]="globalData" [editorialData]="editorialData"></org-ova-form>
    </section>

    <section class="whiteLabelTemplate__section whiteLabelTemplate__ova-success" *ngIf="fragment === 'erfolgreichem-abschluss'">
        <org-ova-success [editorialData]="editorialData" [whiteLabel]="whitelabel"></org-ova-success>
    </section>

    <section class="whiteLabelTemplate__section whiteLabelTemplate__ova-success" *ngIf="fragment === 'erfolgreichem-abschluss-no-confirmation'">
        <org-ova-success noconfirmation="true" [editorialData]="editorialData" [whiteLabel]="whitelabel"></org-ova-success>
    </section>

    <section class="whiteLabelTemplate__section whiteLabelTemplate__client-info">
        <mol-contact-info [contactInfoData]="editorialData.ovaPage.contact" [whitelabel]="whitelabel"></mol-contact-info>
    </section>

</main>

<footer class="whitelabelTemplate__footer">
    <org-footer [whitelabel]="whitelabel"></org-footer>
</footer>
