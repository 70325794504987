import {Component, Input} from "@angular/core";

@Component({
    selector: "mol-textbox",
    templateUrl: "./textbox.component.html",
    styleUrls: ["./textbox.component.scss"]
})

export class TextboxComponent {
    @Input() public textData: any;
}
