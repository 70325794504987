import { Component, Input, OnInit } from "@angular/core";
import { IamService } from "@rem/iam";

@Component({
	selector: "org-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
	@Input() public title: string;
	@Input() public subTitle: string;
	@Input() public buttonText: string;
	@Input() public path: string;
	@Input() public srcPath: string;
	@Input() public imgAltText: string;
	@Input() public whitelabel: string;
	@Input() public configuration: any;
	public isLoggedIn = IamService.isLoggedIn();
	public isFixed: boolean;
	showLoginButton = true;

	ngOnInit() {
		// Disable login button on login page
		this.showLoginButton = location.pathname !== `/${this.whitelabel}/login`;
	}

	scrollToTellAFriend() {
		document.querySelector("#tell-a-friend").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
	}

	hasTellAFriendSection() {
		return document.querySelector("#tell-a-friend") !== null;
	}

	scrollToPriceCalculator() {
		document.querySelector("#pricecalculator").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
	}

	logout() {
		IamService.logout(false);
	}

	onRoute(): void {
		// It's a business requirement that clicking the customer portal icon while
		// being in the customer portal should refresh the page to give the user
		// feedback that clicking worked.
		// Unfortunately, Angular router links won't reload the page, unless you
		// reconfigure the RouterModule, the Routes array, as well as this component.
		// This is a simple work-around, to keep my sanity. Reloading the page is slow,
		// of course, but that's expected...
		if (location.pathname === `/${this.whitelabel}/kundenportal`) {
			location.reload();
		}
	}
}
