import { API_ROOT_URL } from './env';
import { Http } from './http';
import { LocalStorage } from './localstorage';
import { Region } from './region';

export class IamApi {
	static async login(email: string, password: string): Promise<{ token: string }> {
		let res = await fetch(`${API_ROOT_URL}/api/iam/login`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				userId: email,
				password: password
			})
		});
		if (res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
		return res.json();
	}

	static async changePassword(options: { newPassword: string; oldPassword: string }): Promise<{ token: string }> {
		let res = await fetch(`${API_ROOT_URL}/api/iam/changepassword`, {
			method: "POST",
			headers: {
				"Content-Type": "application/vnd.api+json",
				Authorization: `Bearer ${IamService.getToken()}`
			},
			body: JSON.stringify({
				newPassword: options.newPassword,
				oldPassword: options.oldPassword
			})
		});
		if (res.status !== Http.CREATED && res.status !== Http.OKAY) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
		return res.json();
	}

	static async resetPassword(region: string, email: string): Promise<void> {
		let res = await fetch(`${API_ROOT_URL}/api/iam/public/requestnewpassword`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				userId: email,
				context: region
			})
		});
		if (res.status !== Http.ACCEPTED) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
	}

	static async setPasswort(options: { email: string; password: string; hash: string }): Promise<void> {
		let res = await fetch(`${API_ROOT_URL}/api/iam/public/setnewpassword`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				userId: options.email,
				password: options.password,
				hash: options.hash
			})
		});
		if (res.status !== Http.CREATED) {
			// TODO(Mark): Provide more specific error handling
			throw new Error("Upps, da ist etwas schief gelaufen! Das tut uns leid.");
		}
	}

	static async changeUserId(email: string) {
		let res = await fetch(`${API_ROOT_URL}/api/iam/changeuserid`, {
			method: "POST",
			headers: {
				Accept: "application/vnd.api+json",
				Authorization: `Bearer ${IamService.getToken()}`,
				"Content-Type": "application/vnd.api+json"
			},
			body: JSON.stringify({ email })
		});

		if (res.status === Http.BAD_REQUEST) {
			let failedReqBody = await res.json();
			for (let error of failedReqBody.errors) {
				if (error.code === "EMAIL_ALREADY_TAKEN") {
					throw new Error("Diese Emailadresse existiert in unserem System schon!");
				}
			}
		}

		if (res.status !== Http.OKAY) {
			throw new Error("Beim Ändern Ihrer Emailadresse ist ein Fehler aufgetreten. Das tut uns leid.");
		}

		let resBody = await res.json();
		// After Emailchange, a new token is returned by the api and needs to be put in LocalStorage
		IamService.login(resBody.token);
	}
}

export class IamService {
	static login(token: string): void {
		LocalStorage.setItem("jwt_token", token);
		LocalStorage.setItem("jwt_token_time", Date.now().toString());
	}

	static isLoggedIn(): boolean {
		let time = LocalStorage.getItem("jwt_token_time");
		if (time === null) return false;
		let diffMs = Date.now() - parseInt(time);
		let twoHoursMs = 1000 * 60 * 60 * 2;
		if (diffMs >= twoHoursMs) return false;
		return LocalStorage.getItem("jwt_token") !== null;
	}

	static logout(hard = true): void {
		LocalStorage.removeItem("jwt_token");
		LocalStorage.removeItem("jwt_token_time");
		// TODO(Mark): Cleanup
		if (hard) {
			location.href = `${location.protocol}//${location.host}/${Region.getCurrent()}`;
		} else {
			// This only works sometimes (for example, when clicking the Logout button,
			// but not when an API call returns Unauthorized)
			history.pushState({}, "", `/${Region.getCurrent()}`);
		}
	}

	static getToken(): string {
		return LocalStorage.getItem("jwt_token");
	}
}
