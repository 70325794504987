<ng-container *ngIf="ctaConfig.shouldOpenInNewTab; else routerLink">
    <a class="cta-link" [ngClass]="ctaConfig.cssClass" rel="noopener" [href]="ctaConfig.link" [title]="ctaConfig.title">
        {{ ctaConfig.label }}
        <svg class="contactInfo__button__icon" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
        </svg>
    </a>
</ng-container>
<ng-template #routerLink>
    <a class="cta-link" [ngClass]="ctaConfig.cssClass" [routerLink]="ctaConfig.link">
        {{ ctaConfig.label }}
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
            <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
        </svg>
    </a>
</ng-template>
