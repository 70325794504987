import {Component, Input} from "@angular/core";

@Component({
    selector: "ato-text-paragraph",
    templateUrl: "./text-paragraph.component.html",
    styleUrls: ["./text-paragraph.component.scss"],
})

export class TextParagraphComponent {
    @Input() public text: string;
}

