/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toggle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./toggle.component";
var styles_ToggleComponent = [i0.styles];
var RenderType_ToggleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToggleComponent, data: {} });
export { RenderType_ToggleComponent as RenderType_ToggleComponent };
function View_ToggleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ToggleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "toggle-item"]], [[2, "active", null], [1, "target", 0], [8, "href", 4]], [[null, "touchstart"], [null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.touchstartToClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = ((_co.isCustomerHover = true) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = ((_co.isCustomerHover = false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_2)), i1.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, ("/" + _co.whitelabel)); _ck(_v, 2, 0, currVal_3); var currVal_4 = i1.ɵnov(_v.parent, 10); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCustomerHover || (_co.customer && !_co.isProducerHover)); var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ToggleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ToggleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "toggle-item"], ["type", "button"]], [[2, "active", null]], [[null, "click"], [null, "touchstart"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToPriceCalculator() !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.touchstartToClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = ((_co.isCustomerHover = true) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = ((_co.isCustomerHover = false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_4)), i1.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = i1.ɵnov(_v.parent, 10); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCustomerHover || (_co.customer && !_co.isProducerHover)); _ck(_v, 1, 0, currVal_0); }); }
function View_ToggleComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ToggleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "toggle-item"]], [[2, "active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"], [null, "touchstart"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.isProducerHover = false) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (_co.touchstartToClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = ((_co.isProducerHover = true) !== false);
        ad = (pd_3 && ad);
    } if (("mouseleave" === en)) {
        var pd_4 = ((_co.isProducerHover = false) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_6)), i1.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, ("/" + _co.whitelabel), _co.pageUrl); _ck(_v, 2, 0, currVal_3); var currVal_4 = i1.ɵnov(_v.parent, 11); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isProducerHover || (!_co.customer && !_co.isCustomerHover)); var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ToggleComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ToggleComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "toggle-item"]], [[2, "active", null]], [[null, "click"], [null, "touchstart"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollToProducerForm() !== false);
        ad = (pd_0 && ad);
    } if (("touchstart" === en)) {
        var pd_1 = (_co.touchstartToClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = ((_co.isProducerHover = true) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = ((_co.isProducerHover = false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_8)), i1.ɵdid(3, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = i1.ɵnov(_v.parent, 11); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isProducerHover || (!_co.customer && !_co.isCustomerHover)); _ck(_v, 1, 0, currVal_0); }); }
function View_ToggleComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "toggle-item__headline no-hyphens"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "toggle-item__link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tarif berechnen"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:svg", [["height", "36"], ["viewBox", "0 0 36 36"], ["width", "36"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:path", [["d", "M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"], ["fill-rule", "nonzero"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "toggle-item__mobile"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.switchData.consumer.text1; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.switchData.consumer.textHighlight; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.switchData.consumer.text2; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.switchData.consumer.textMobile; _ck(_v, 10, 0, currVal_3); }); }
function View_ToggleComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "toggle-item__headline no-hyphens"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "toggle-item__link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, ":svg:svg", [["height", "36"], ["viewBox", "0 0 36 36"], ["width", "36"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:path", [["d", "M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"], ["fill-rule", "nonzero"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "toggle-item__mobile"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.switchData.producer.text1; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.switchData.producer.textHighlight; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.switchData.producer.text2; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.switchData.producer.cta; _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.switchData.producer.textMobile; _ck(_v, 9, 0, currVal_4); }); }
export function View_ToggleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "toggle"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_5)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToggleComponent_7)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["customerButton", 2]], null, 0, null, View_ToggleComponent_9)), (_l()(), i1.ɵand(0, [["producerButton", 2]], null, 0, null, View_ToggleComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.customer; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.customer; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.customer; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.customer; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ToggleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "org-toggle", [], null, null, null, View_ToggleComponent_0, RenderType_ToggleComponent)), i1.ɵdid(1, 49152, null, 0, i4.ToggleComponent, [], null, null)], null, null); }
var ToggleComponentNgFactory = i1.ɵccf("org-toggle", i4.ToggleComponent, View_ToggleComponent_Host_0, { switchData: "switchData", pageUrl: "pageUrl", whitelabel: "whitelabel", editorialData: "editorialData", customer: "customer", jumpTarget: "jumpTarget" }, {}, []);
export { ToggleComponentNgFactory as ToggleComponentNgFactory };
