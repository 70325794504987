import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@p2p/utils/auth.guard";
import { ClientPortalPageComponent } from "@p2p/pages/client-portal-page/client-portal-page.component";
// import { ContactFormPageComponent } from "@p2p/pages/contact-form-page/contact-form-page.component";
// import { DisclaimerPageComponent } from "@p2p/pages/disclaimer-page/disclaimer-page.component";
import { DisclaimerPageAltComponent } from "@p2p/pages/disclaimer-page/disclaimer-page-alt.component";
// import { ContactFormV2PageAltComponent } from "@p2p/pages/contact-form-v2-page/contact-form-v2-page-alt.component";
import { ContactFormV2PageComponent } from "@p2p/pages/contact-form-v2-page/contact-form-v2-page.component";
// import { FaqPageAltComponent } from "@p2p/pages/faq-page/faq-page-alt.component";
import { FaqPageComponent } from "@p2p/pages/faq-page/faq-page.component";
// import { LandingPageComponent } from "@p2p/pages/landing-page/landing-page.component";
// import { LegalPageComponent } from "@p2p/pages/legal-page/legal-page.component";
import { LegalPageAltComponent } from "@p2p/pages/legal-page/legal-page-alt.component";
import { LoginPageComponent } from "@p2p/pages/login-page/login-page.component";
import { OvaPageComponent } from "@p2p/pages/ova-page/ova-page.component";
// import { AgbBagComponent } from "@p2p/pages/agb-bag/agb-bag.component";
import { AgbPageComponent } from "@p2p/pages/agb-page/agb-page.component";
import { WhiteLabelPageComponent } from "@p2p/pages/white-label-page/white-label-page.component";
import { WhiteLabelErzeugerPageComponent } from "@p2p/pages/white-label-erzeuger-page/white-label-erzeuger-page.component";
// import { LeadPageComponent } from "@p2p/pages/lead-page/lead-page.component";
// import { LeadConfirmPageComponent } from "@p2p/pages/lead-confirm-page/lead-confirm-page.component";
import { RedesignComponent } from "./redesign.component";

// TODO: Remove unused routes

const routes: Routes = [
	// { path: "contact", component: ContactFormPageComponent },
	// { path: "agb", component: AgbBagComponent },
	// { path: "kontaktformular", component: ContactFormV2PageAltComponent },
	// { path: "impressum", component: DisclaimerPageComponent },
	// { path: "datenschutz", component: LegalPageComponent },
	// { path: "faq", component: FaqPageAltComponent },
	// { path: "angebot", component: LeadPageComponent },
	// { path: "angebot/optin", component: LeadConfirmPageComponent },
	{ path: ":region", component: RedesignComponent },
	{ path: ":region/agb", component: AgbPageComponent },
	{ path: ":region/kontaktformular", component: ContactFormV2PageComponent },
	{ path: ":region/ova", component: OvaPageComponent },
	{ path: ":region/login", component: LoginPageComponent },
	{ path: ":region/passwort-hilfe", component: LoginPageComponent },
	{ path: ":region/passwort-aendern", component: LoginPageComponent },
	{ path: ":region/kundenportal", component: ClientPortalPageComponent, canActivate: [AuthGuard] },
	{ path: ":region/impressum", component: DisclaimerPageAltComponent },
	{ path: ":region/datenschutz", component: LegalPageAltComponent },
	{ path: ":region/faq", component: FaqPageComponent },
	{ path: ":region/erzeuger", component: WhiteLabelErzeugerPageComponent },
	// { path: "", component: LandingPageComponent },
	{ path: "**", redirectTo: "/oberguenzburg" }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
