import { Component, Input, OnInit } from "@angular/core";
import { ICookieSettings } from "@p2p/molecules/cookie-disclaimer/cookie-disclaimer.component";

@Component({
	selector: "org-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
	public hasCookieDisclaimer: boolean;
	public now: Date;
	@Input() public whitelabel: string;

	constructor() {
		// Intentionally true at first.
		this.hasCookieDisclaimer = false;
	}

	scrollToTellAFriend() {
		document.querySelector("#tell-a-friend").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
	}

	hasTellAFriendSection() {
		return document.querySelector("#tell-a-friend") !== null;
	}

	public ngOnInit(): void {
		// Once rendering this part it is safe to assume that the cookieSettings have alread been set.
		// Therefore we need to look reciprocally at the cookieSettings to manipulate the footer's height comparing to cookie-disclaimer-component.ts
		const cookieSettings: ICookieSettings = JSON.parse(localStorage.getItem("cookieSettings"));
		if (cookieSettings && cookieSettings.ttl) {
			this.hasCookieDisclaimer = new Date(cookieSettings.ttl) > this.now ? true : false;
		}
	}
}
