/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-paragraph.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./text-paragraph.component";
var styles_TextParagraphComponent = [i0.styles];
var RenderType_TextParagraphComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextParagraphComponent, data: {} });
export { RenderType_TextParagraphComponent as RenderType_TextParagraphComponent };
export function View_TextParagraphComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "text"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 0, 0, currVal_0); }); }
export function View_TextParagraphComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-text-paragraph", [], null, null, null, View_TextParagraphComponent_0, RenderType_TextParagraphComponent)), i1.ɵdid(1, 49152, null, 0, i2.TextParagraphComponent, [], null, null)], null, null); }
var TextParagraphComponentNgFactory = i1.ɵccf("ato-text-paragraph", i2.TextParagraphComponent, View_TextParagraphComponent_Host_0, { text: "text" }, {}, []);
export { TextParagraphComponentNgFactory as TextParagraphComponentNgFactory };
