/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cta-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./cta-button.component";
var styles_CtaButtonComponent = [i0.styles];
var RenderType_CtaButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CtaButtonComponent, data: {} });
export { RenderType_CtaButtonComponent as RenderType_CtaButtonComponent };
function View_CtaButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, ":svg:svg", [["height", "36"], ["viewBox", "0 0 36 36"], ["width", "36"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"], ["fill-rule", "nonzero"]], null, null, null, null, null))], null, null); }
export function View_CtaButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "cta-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ctaConfig.clickEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtaButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "cta-button"; var currVal_1 = _co.ctaConfig.cssClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.ctaConfig.showIcon; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.ctaConfig.label; _ck(_v, 3, 0, currVal_2); }); }
export function View_CtaButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-cta-button", [], null, null, null, View_CtaButtonComponent_0, RenderType_CtaButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.CtaButtonComponent, [], null, null)], null, null); }
var CtaButtonComponentNgFactory = i1.ɵccf("ato-cta-button", i3.CtaButtonComponent, View_CtaButtonComponent_Host_0, { ctaConfig: "ctaConfig" }, {}, []);
export { CtaButtonComponentNgFactory as CtaButtonComponentNgFactory };
