<main class="contactFormTemplate lazyload-trigger" *ngIf="showTemplate" (deferLoad)="showImage=true">
    <section class="contactFormTemplate__subheader" id="producerForm">
        <div class="contact-form__subheader">
            <!--<org-subheader [subheaderData]="globalData.subheader"></org-subheader>-->
            <ato-image class="lazyload" *ngIf="showImage" [image]="producerForm.image"></ato-image>
            <div class="container">
                <div class="row">
                    <div class="contact-form__subheader__wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-4 col-xl-10 col-xl-push-1">
                                    <h4 class="contact-form__switch-contracts__headline" [innerHTML]="producerForm.content.headline"></h4>
                                    <p [innerHTML]="producerForm.content.text"></p>
                                    <button (click)="formToggler()" class="toggle">
                                        <span *ngIf="!toggleForm" [innerHTML]="producerForm.content.buttonOpen"></span>
                                        <span *ngIf="toggleForm" [innerHTML]="producerForm.content.buttonClose"></span>
                                    </button>
                                    <button (click)="formToggler()" class="cross" [class.active]="toggleForm"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="contactFormTemplate__form" [class.open]="toggleForm">
        <org-contact-form [whitelabel]="whitelabel" [globalData]="globalData"></org-contact-form>
    </section>
</main>
