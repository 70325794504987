<main class="contactFormV2Template">
    <!--insert header here-->
    <div class="contactFormV2Template__header">
        <org-header [whitelabel]="whitelabel"></org-header>
    </div>
    <section class="contactFormV2Template__subheader">
        <div class="contact-form-v2__subheader">
            <div class="container">
                <div class="row">
                    <div class="contact-form-v2__subheader__wrapper">
                        <div class="container">
                            <div class="row">
                                <div class="col-xs-4 col-md-8 col-lg-4 col-xl-5">
                                    <a *ngIf="fromCsc" [routerLink]="['/' + this.whitelabel + '/kundenportal']" routerLinkActive="active" class="contactFormV2Template__subheader-goback">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                            <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
                                        </svg>
                                        <span [innerHTML]="globalData.contactForm.backToCsc"></span>
                                    </a>
                                    <a *ngIf="!fromCsc" [routerLink]="['/' + this.whitelabel]" routerLinkActive="active" class="contactFormV2Template__subheader-goback">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                            <path fill-rule="nonzero" d="M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"/>
                                        </svg>
                                        <span [innerHTML]="globalData.contactForm.backToStart"></span>
                                    </a>
                                    <h1 [innerHTML]="fromCsc ? globalData.contactForm.csc.openerHead : globalData.contactForm.openerHead"></h1>
                                    <p [innerHTML]="fromCsc ? globalData.contactForm.csc.openerText : globalData.contactForm.openerText"></p>
                                </div>
                                <div class="col-xs-4 col-md-8 col-lg-4 col-xl-6 col-xl-push-1">
                                    <picture>
                                        <img [src]="globalData.contactForm.mediaUrl" [alt]="globalData.contactForm.mediaAlt">
                                    </picture>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="contactFormV2Template__form">
        <org-contact-form-v2 [whitelabel]="whitelabel" [editorialData]="editorialData"></org-contact-form-v2>
    </section>
</main><!--insert footer here-->
<footer class="contactFormV2Template__footer">
    <org-footer [whitelabel]="whitelabel"></org-footer>
</footer>
