<main class="whitelabelTemplate">
    <div class="whitelabelTemplate__header">
        <org-header [whitelabel]="whitelabel" [configuration]="editorialData.producerPage.header"></org-header>
    </div>
    <section class="whitelabelTemplate__regionalContent">
        <org-regional-content [siteArea]="'producerPage'"></org-regional-content>
    </section>
    <section class="whitelabelTemplate__toggle" *ngIf="toggleJumpTarget">
        <org-toggle [jumpTarget]="toggleJumpTarget" [customer]="false" [editorialData]="editorialData" [whitelabel]="whitelabel" [pageUrl]="pageUrl" [switchData]="globalData.consumerCreatorSwitch"></org-toggle>
    </section>
    <section class="whitelabelTemplate__textBox">
        <mol-textbox [textData]="editorialData.producerPage.explanationText"></mol-textbox>
    </section>
    <section class="whitelabelTemplate__imageSlider">
        <org-image-slider [imageSliderData]="editorialData.producerPage.imageSlider"></org-image-slider>
    </section>
    <section class="whitelabelTemplate__imageTeaser" *ngIf="editorialData.producerPage.introImageTeaser && editorialData.producerPage.introImageTeaser.visible">
        <org-image-teaser [imageTeaserData]="editorialData.producerPage.introImageTeaser"></org-image-teaser>
    </section>
    <section class="whitelabelTemplate__producer-info">
        <org-producer-info [producerInfoData]="editorialData.producerPage.advantages"></org-producer-info>
    </section>
    <section #toggleJumpTarget class="whitelabelTemplate__contactForm">
        <tpl-contact-form [whitelabel]="whitelabel" [globalData]="globalData" [producerForm]="editorialData.producerPage.producerForm"></tpl-contact-form>
    </section>
    <section class="whitelabelTemplate__quote">
        <org-quote [quoteData]="editorialData.producerPage.quote"></org-quote>
    </section>
    <section class="whiteLabelTemplate__section whiteLabelTemplate__contact-info">
        <mol-contact-info [contactInfoData]="editorialData.producerPage.contact" [whitelabel]="whitelabel"></mol-contact-info>
    </section>
</main><!--insert footer here-->
<footer class="whitelabelTemplate__footer">
    <org-footer [whitelabel]="whitelabel"></org-footer>
</footer>
