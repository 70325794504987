<main class="contentModul">
    <section class="legalPage__body">
        <div class="container">
            <div class="row">
                <div class="col-xs-4 col-md-8 col-xl-4 col-xl-push-1">
                    <p class="contentModul__heading--prefix">{{ producerInfoData.subheadline }}</p>
                    <h2 class="contentModul__heading">
                        {{ producerInfoData.headline1 }}
                        <span>{{ producerInfoData.headlineHighlighted }}</span>{{ producerInfoData.headline2 }}
                    </h2>
                    <div class="row">
                        <div class="col-xl-10 col-xl-push-2">
                            <div class="contentModul__textarea">
                                <p [innerHTML]="producerInfoData.text"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-4 col-md-8 col-xl-6 col-xl-push-1">
                    <ul class="contentModul__list">
                        <li class="contentModul__listItem" *ngFor="let advantage of producerInfoData.list; let i = index">
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                <path fill-rule="nonzero" d="M27.639 10L29 11.347 14.11 26 7 19.033l1.358-1.35 5.75 5.634z"/>
                            </svg>
                            <h3 class="contentModul__listItem--heading">{{ advantage.headline }}</h3>
                            <p class="contentModul__listItem--text">
                                {{ advantage.text }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</main>
