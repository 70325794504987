/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cta-link.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./cta-link.component";
var styles_CtaLinkComponent = [i0.styles];
var RenderType_CtaLinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CtaLinkComponent, data: {} });
export { RenderType_CtaLinkComponent as RenderType_CtaLinkComponent };
function View_CtaLinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "cta-link"], ["rel", "noopener"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["class", "contactInfo__button__icon"], ["height", "36"], ["viewBox", "0 0 36 36"], ["width", "36"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:path", [["d", "M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"], ["fill-rule", "nonzero"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "cta-link"; var currVal_3 = _co.ctaConfig.cssClass; _ck(_v, 3, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ctaConfig.link; var currVal_1 = _co.ctaConfig.title; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.ctaConfig.label; _ck(_v, 4, 0, currVal_4); }); }
function View_CtaLinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "cta-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["height", "36"], ["viewBox", "0 0 36 36"], ["width", "36"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:path", [["d", "M27.083 19.07H5v-2.14h22.083l-6.138-6.417L22.392 9 31 18l-8.608 9-1.447-1.513 6.138-6.417z"], ["fill-rule", "nonzero"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "cta-link"; var currVal_3 = _co.ctaConfig.cssClass; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.ctaConfig.link; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _co.ctaConfig.label; _ck(_v, 4, 0, currVal_5); }); }
export function View_CtaLinkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CtaLinkComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["routerLink", 2]], null, 0, null, View_CtaLinkComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ctaConfig.shouldOpenInNewTab; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CtaLinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ato-cta-link", [], null, null, null, View_CtaLinkComponent_0, RenderType_CtaLinkComponent)), i1.ɵdid(1, 49152, null, 0, i4.CtaLinkComponent, [], null, null)], null, null); }
var CtaLinkComponentNgFactory = i1.ɵccf("ato-cta-link", i4.CtaLinkComponent, View_CtaLinkComponent_Host_0, { ctaConfig: "ctaConfig" }, {}, []);
export { CtaLinkComponentNgFactory as CtaLinkComponentNgFactory };
