/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./white-label-erzeuger-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../templates/white-label-erzeuger-template/white-label-erzeuger-template.component.ngfactory";
import * as i3 from "../../templates/white-label-erzeuger-template/white-label-erzeuger-template.component";
import * as i4 from "@angular/common";
import * as i5 from "./white-label-erzeuger-page.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../services/seo.service";
var styles_WhiteLabelErzeugerPageComponent = [i0.styles];
var RenderType_WhiteLabelErzeugerPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WhiteLabelErzeugerPageComponent, data: {} });
export { RenderType_WhiteLabelErzeugerPageComponent as RenderType_WhiteLabelErzeugerPageComponent };
function View_WhiteLabelErzeugerPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tpl-white-label-erzeuger", [], null, null, null, i2.View_WhiteLabelErzeugerTemplateComponent_0, i2.RenderType_WhiteLabelErzeugerTemplateComponent)), i1.ɵdid(1, 49152, null, 0, i3.WhiteLabelErzeugerTemplateComponent, [], { globalData: [0, "globalData"], editorialData: [1, "editorialData"], whitelabel: [2, "whitelabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.globalData; var currVal_1 = _co.editorialData; var currVal_2 = _co.whitelabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_WhiteLabelErzeugerPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "whitelabelPage"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WhiteLabelErzeugerPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTemplateGlobal && _co.showTemplateEditorial); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WhiteLabelErzeugerPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pag-white-label-erzeuger", [], null, null, null, View_WhiteLabelErzeugerPageComponent_0, RenderType_WhiteLabelErzeugerPageComponent)), i1.ɵdid(1, 245760, null, 0, i5.WhiteLabelErzeugerPageComponent, [i6.ActivatedRoute, i7.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WhiteLabelErzeugerPageComponentNgFactory = i1.ɵccf("pag-white-label-erzeuger", i5.WhiteLabelErzeugerPageComponent, View_WhiteLabelErzeugerPageComponent_Host_0, {}, {}, []);
export { WhiteLabelErzeugerPageComponentNgFactory as WhiteLabelErzeugerPageComponentNgFactory };
