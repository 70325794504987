import { ENV } from "./new/env";
import { Component } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
	selector: "redesign",
	template: /*html*/ `
		<iframe [src]="this.frame_src"></iframe>
	`,
	styles: [
		/*css*/ `
			iframe {
				position: fixed;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				width: 100%;
				height: 100%;
				border: none;
				margin: 0;
				padding: 0;
				z-index: 999;
			}
		`
	]
})
export class RedesignComponent {
	frame_src: SafeResourceUrl;

	constructor(private sanitizer: DomSanitizer) {
		let tenant = "lew";
		let region = "oberguenzburg";
		let ref = encodeURIComponent(`${location.protocol}//${location.host}${location.pathname}`);

		let frame_env = ENV === "qa" ? "qas" : ENV;
		let frame_base = `https://remdesign${frame_env}.z6.web.core.windows.net`; // "http://localhost:3000"
		let frame_src = `${frame_base}/index.html?tenant=${tenant}&region=${region}&ref=${ref}`;

		this.frame_src = this.sanitizer.bypassSecurityTrustResourceUrl(frame_src);
	}
}
