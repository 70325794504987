import {AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {isPlatformBrowser} from "@angular/common";

@Component({
    selector: "org-quote",
    templateUrl: "./quote.component.html",
    styleUrls: ["./quote.component.scss"],
})

export class QuoteComponent implements AfterViewInit {
    @Input() public quoteData;
    @ViewChildren("quoteElem") public quoteElems: QueryList<any>;
    @ViewChild("quoteContainer", {static: false}) public quoteContainer: ElementRef;
    private fadeInterval: number;
    private intervalCounter = 0;
    public showImage = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.fadeInterval = this.quoteData.interval;
            this.fadeElements(this.quoteElems, this.fadeInterval);
        }
    }

    setContainerHeight(): void {
        const elementsList = this.quoteElems.toArray();
        let highestHeight = 0;
        let tempHeight: number;

        elementsList.forEach((elem) => {
            tempHeight = elem.nativeElement.offsetHeight;
            if (tempHeight > highestHeight) {
                highestHeight = tempHeight;
            }
        });
        this.quoteContainer.nativeElement.style.height = highestHeight + "px";
    }

    fadeElements(elements: any, interval: number): void {
        const listLength = elements.length;
        const elementsList = elements.toArray();

        this.setContainerHeight();

        // timeout for cycling functionality:
        setTimeout(() => {
            this.intervalCounter++;
            elementsList.forEach((elem) => {
                elem.nativeElement.classList.remove("active");
            });

            if (this.intervalCounter >= listLength) {
                this.intervalCounter = 0;
            }

            if (elementsList[this.intervalCounter] && elementsList[this.intervalCounter].nativeElement) {
                elementsList[this.intervalCounter].nativeElement.classList.add("active");
            }

            this.fadeElements(this.quoteElems, this.fadeInterval);
        }, interval);
    }
}
