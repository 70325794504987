import {Component, Input} from "@angular/core";

@Component({
    selector: "ato-subheadline",
    templateUrl: "./subheadline.component.html",
    styleUrls: ["./subheadline.component.scss"],
})

export class SubheadlineComponent {
    @Input() subheadline: string;

    constructor(
    ) {
    }
}

