import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "tpl-contact-form",
    templateUrl: "./contact-form-template.component.html",
    styleUrls: ["./contact-form-template.component.scss"],
})

export class ContactFormTemplateComponent implements OnInit {
    @Input() public globalData: any;
    @Input() public producerForm: any;
    @Input() public whitelabel: string;
    public toggleForm = false;
    public showTemplate = false;
    public showImage = false;

    formToggler(): void {
        if (!this.toggleForm) {
            this.toggleForm = true;
        } else {
            this.toggleForm = false;
        }
    }

    ngOnInit(): void {
        this.showTemplate = true;
    }
}
