/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./textbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../call-to-action/call-to-action.component.ngfactory";
import * as i3 from "../call-to-action/call-to-action.component";
import * as i4 from "../../atoms/subheadline/subheadline.component.ngfactory";
import * as i5 from "../../atoms/subheadline/subheadline.component";
import * as i6 from "../../atoms/headline/headline.component.ngfactory";
import * as i7 from "../../atoms/headline/headline.component";
import * as i8 from "../../atoms/text-paragraph/text-paragraph.component.ngfactory";
import * as i9 from "../../atoms/text-paragraph/text-paragraph.component";
import * as i10 from "@angular/common";
import * as i11 from "./textbox.component";
var styles_TextboxComponent = [i0.styles];
var RenderType_TextboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextboxComponent, data: {} });
export { RenderType_TextboxComponent as RenderType_TextboxComponent };
function View_TextboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mol-call-to-action", [], null, null, null, i2.View_CallToActionComponent_0, i2.RenderType_CallToActionComponent)), i1.ɵdid(1, 49152, null, 0, i3.CallToActionComponent, [], { callToActionData: [0, "callToActionData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textData.callToAction; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TextboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "textbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "col-xs-4 col-md-7 col-lg-9 col-xl-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ato-subheadline", [], null, null, null, i4.View_SubheadlineComponent_0, i4.RenderType_SubheadlineComponent)), i1.ɵdid(5, 49152, null, 0, i5.SubheadlineComponent, [], { subheadline: [0, "subheadline"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ato-headline", [], null, null, null, i6.View_HeadlineComponent_0, i6.RenderType_HeadlineComponent)), i1.ɵdid(7, 49152, null, 0, i7.HeadlineComponent, [], { headline: [0, "headline"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-xs-4 col-md-7 col-md-push-1 col-lg-7 col-lg-push-1 col-xl-8 col-xl-push-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "ato-text-paragraph", [], null, null, null, i8.View_TextParagraphComponent_0, i8.RenderType_TextParagraphComponent)), i1.ɵdid(10, 49152, null, 0, i9.TextParagraphComponent, [], { text: [0, "text"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextboxComponent_1)), i1.ɵdid(12, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textData.subheadline; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.textData.headline; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.textData.text; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.textData.callToAction; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_TextboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mol-textbox", [], null, null, null, View_TextboxComponent_0, RenderType_TextboxComponent)), i1.ɵdid(1, 49152, null, 0, i11.TextboxComponent, [], null, null)], null, null); }
var TextboxComponentNgFactory = i1.ɵccf("mol-textbox", i11.TextboxComponent, View_TextboxComponent_Host_0, { textData: "textData" }, {}, []);
export { TextboxComponentNgFactory as TextboxComponentNgFactory };
